import routes1 from "@/router/industries/1/routes";
import routes2 from "@/router/industries/2/routes";

import { RouteConfig } from "vue-router";

const routes: { [x: string]: RouteConfig[] } = {
  "1": routes1,
  "2": routes2,
};

export default routes[process.env.VUE_APP_INDUSTRY_ID ?? "1"];
