import { KcmProfilePostData, SelectOptions } from "@/types/index";
import {
  KCMPPProfile,
  KCMPPField,
  KCMPPFieldType,
  KCMPPValuesTableData,
  KCMPPProfilesResponse,
  KCMPPFieldConverted,
} from "@/types/kcm-profile-api.types";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import store from "@/state/store";
import { helpers } from "@/services/helpers";
import { thirdPartyService } from "./thirdparty.service";
import router from "@/router";
import TrackingService from "@/services/trackingservice/tracking.service";

function queryVars(): string {
  const path = router.currentRoute.fullPath;
  return (
    "?source=" +
    (process.env.VUE_APP_SITE_NAME ?? "MyKCM") +
    ` ${path}&user=member`
  );
}

const HOST = process.env.VUE_APP_PP_API_ROOT
  ? process.env.VUE_APP_PP_API_ROOT.replace(/\/$/, "")
  : "";
const HEADERS = (): AxiosRequestConfig => {
  const userToken = store.getters["auth/authToken"];
  if (userToken !== undefined && userToken !== null) {
    return {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    };
  }

  return {};
};
const industryId = process.env.VUE_APP_INDUSTRY_ID ?? "1";

const dropdownFields: SelectOptions[] = [
  {
    text: "First Name",
    value: "first_name",
  },
  {
    text: "Last Name",
    value: "last_name",
  },
  {
    text: "Full Name",
    value: "full_name",
  },
  {
    text: "Company",
    value: "company",
  },
  {
    text: "Job Title",
    value: "job_title",
  },
  {
    text: "Location",
    value: "location",
  },
  {
    text: "Email",
    value: "email",
  },
  {
    text: "Phone",
    value: "phone",
  },
  {
    text: "Website",
    value: "website",
  },
  {
    text: "Bio",
    value: "bio",
  },
  {
    text: "Designations",
    value: "designations",
  },
  {
    text: "Compliance",
    value: "compliance",
  },
  {
    text: "Custom",
    value: "custom",
  },
  {
    text: "Hide",
    value: "hide",
  },
];

const logoPositions: SelectOptions[] = [
  {
    text: "Hide",
    value: "0",
  },
  {
    text: "Top Left in the Header",
    value: "1",
  },
  {
    text: "Underneath My Photo",
    value: "2",
  },
  {
    text: "In the Footer",
    value: "3",
  },
];

const languageOptions: SelectOptions[] = [
  {
    text: "English",
    value: "en",
  },
  {
    text: "Spanish",
    value: "es",
  },
];

const emailLanguageOptions: SelectOptions[] = [
  {
    text: "English",
    value: "EN",
  },
  {
    text: "Spanish",
    value: "ES",
  },
];

const emailGuidePositions: SelectOptions[] = [
  {
    text: "Hide",
    value: "0",
  },
  {
    text: "Footer",
    value: "1",
  },
  {
    text: "Sidebar",
    value: "2",
  },
];

const emailLogoPositions: SelectOptions[] = [
  {
    text: "Hide",
    value: "0",
  },
  {
    text: "Header",
    value: "1",
  },
  {
    text: "Footer",
    value: "2",
  },
  {
    text: "Sidebar",
    value: "3",
  },
];

const bgOptions: SelectOptions[] = [
  {
    text: "Profile Setting",
    value: "primary_color",
  },
  {
    text: "Custom",
    value: "custom",
  },
];

const headerSchemeOptions: SelectOptions[] = [
  {
    text: "White Background with Personalization Color Text",
    value: "0",
  },
  {
    text: "Personalization Color Background with White Text",
    value: "1",
  },
];

const emailContentFormatOptions: SelectOptions[] = [
  {
    text: "Show Excerpts",
    value: "0",
  },
  {
    text: "Show Headline Excerpt Only",
    value: "1",
  },
  {
    text: "Hide All",
    value: "2",
  },
  {
    text: "Show Full Blog Post",
    value: "3",
  },
];
const uploadFailed = "The file failed to upload";

export const profileService = {
  dropdownFields,
  logoPositions,
  languageOptions,
  emailLanguageOptions,
  emailGuidePositions,
  emailLogoPositions,
  bgOptions,
  headerSchemeOptions,
  emailContentFormatOptions,
  uploadFailed,
  loadProfile,
  updateProfileByFeature,
  memberImageUpload,
  getMemberImages,
  deleteProfileByFeature,
  updateProfileFieldByFeature,
  addProfileFlag,
  styleFontSizeByField,
  uploadCustomIntro,
  uploadCustomOutro,
  uploadScriptImage,
  createDirectory,
  createDefaultProfileFields,
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
function get(resource: string): Promise<AxiosResponse<any, any>> {
  const headers = HEADERS();
  if (!headers.headers || !headers.headers.authorization) {
    return Promise.reject(new Error("No auth token available"));
  }
  return axios.get(HOST + resource, headers);
}

function post(
  resource: string,
  data: unknown
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> {
  const headers = HEADERS();
  if (!headers.headers || !headers.headers.authorization) {
    return Promise.reject(new Error("No auth token available"));
  }
  return axios.post(HOST + resource, data, headers);
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
function deleteAxios(resource: string): Promise<AxiosResponse<any, any>> {
  const headers = HEADERS();
  if (!headers.headers || !headers.headers.authorization) {
    return Promise.reject(new Error("No auth token available"));
  }
  return axios.delete(HOST + resource, headers);
}

async function loadProfile(userId: string): Promise<string | boolean> {
  let result: KCMPPProfilesResponse | string | null = null;
  let retries = 1;
  const maxRetries = 3;
  while (retries <= maxRetries && !result) {
    try {
      const response = await get("/" + userId);
      result = response as KCMPPProfilesResponse;
    } catch (error) {
      if (retries >= maxRetries) {
        result = (error as { response: { data: { message: string } } }).response
          .data.message;
      }
      retries++;
    }
  }

  if (result === null) {
    helpers.customSentryError(
      "Major profile loading malfunction, no profile or error was set.",
      "Error loading profile.",
      {
        error: "No information available",
      }
    );
    return "Error loading profile";
  }

  if (typeof result === "string") {
    if (result !== "No profile found for ID") {
      // record the error message in Sentry if the final message is anything other than No Profile found for ID
      helpers.customSentryError(
        "Loading Profile Error",
        "Error loading profile.",
        {
          error: result,
        }
      );
      return "Error loading profile";
    }
    // otherwise no profile exists, member should be redirected to the setup-profile page
    return false;
  }

  const profile = result.data.profiles;
  await getProfileFlags(userId);

  /** add last updated timestamps to each profile and label all sync to fields */
  store.dispatch("profile/setCurrentProfile", profile);
  return false;
}

async function createDefaultProfileFields(
  userId: string,
  features: string[]
): Promise<boolean> {
  const result = await post(
    "/" +
      userId +
      "/default/multi" +
      queryVars() +
      "&features=" +
      features.join(","),
    {}
  ).catch((err) => {
    return err;
  });

  if (result.data.profiles) {
    store.dispatch("profile/setCurrentProfile", result.data.profiles);
    return false;
  }

  helpers.customSentryError(
    "Creating Default Profile Error",
    "Failed to create Default Profile for Member.",
    {
      error: result.response.data.message,
    }
  );
  return true;
}

async function getProfileFlags(userId: string): Promise<void> {
  const result = await get("/" + userId + "/flags")
    .then((result) => {
      return result;
    })
    .catch(async (error1) => {
      helpers.customSentryError("Loading Flags Error", "Error loading flags.", {
        error1: error1,
      });
      return false;
    });

  if (typeof result === "boolean") {
    return;
  }

  if (result && "data" in result && "flags" in result.data) {
    store.dispatch("profile/setFlags", result.data.flags);
  }
}

async function updateProfileByFeature(
  userId: string,
  feature: string,
  data: KCMPPProfile,
  action = ""
): Promise<boolean> {
  const formattedData = formatProfileResponseDataToPost(data);

  try {
    const result = await post(
      "/" + userId + "/" + feature + queryVars() + action,
      formattedData
    );

    if ("data" in result && "profile" in result.data) {
      if (feature !== "basic") {
        await store.dispatch("profile/setCurrentProfileByFeature", {
          feature: feature,
          profile: result.data.profile,
        });
      } else {
        await loadProfile(userId);

        // sync designations to hubspot
        if (industryId === "2") {
          const basic = store.getters["profile/getProfileByFeature"]("basic");
          thirdPartyService.updateHubspotContact(
            store.getters["auth/authEmail"],
            { company: basic.company.value, kcm_nmls: basic.designations.value }
          );
        }
      }
      TrackingService.trackEvent("save", { eventTarget: `${feature} profile` });

      return false;
    } else {
      throw new Error("Error updating profile");
    }
  } catch (err) {
    helpers.customSentryError(
      "Loading Profile Error",
      "Error loading profile first catch.",
      {
        error: err,
      }
    );
    throw new Error("Error updating profile");
  }
}

async function getMemberImages(
  userId: string,
  imagePath?: string,
  lastKey?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const endpoint = `${HOST}/${userId}/upload?imagePath=${imagePath}&lastKey=${lastKey}`;
  const headers = HEADERS();
  if (!headers.headers || !headers.headers.authorization) {
    return Promise.reject(new Error("No auth token available"));
  }

  try {
    const result = await axios.get(endpoint, headers);
    if (result) {
      return result;
    }
    return "Error getting images";
  } catch (error) {
    helpers.customSentryError(
      "Get Images Error",
      "Error getting images from profile api.",
      {
        error: error,
      }
    );
    return error;
  }
}

async function memberImageUpload(
  userId: string,
  path: string,
  file: Blob
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const endpoint = `${HOST}/${userId}/upload`;
  const formData = new FormData();
  const headers = HEADERS();
  if (!headers.headers || !headers.headers.authorization) {
    return Promise.reject(new Error("No auth token available"));
  }

  if (headers.headers) headers.headers["Content-Type"] = "multipart/form-data";
  formData.append("upload", file);
  formData.append("upload-path", path);

  try {
    const result = await axios.post(endpoint, formData, headers);

    if (result) {
      return result;
    }
    return "Error uploading image";
  } catch (error) {
    helpers.customSentryError(
      "Uploading Image Error",
      "Error posting image to profile api.",
      {
        error: error,
      }
    );
    return error;
  }
}

async function deleteProfileByFeature(
  userId: string,
  feature: string
): Promise<string | boolean> {
  try {
    const result = await deleteAxios("/" + userId + "/" + feature);

    if ("data" in result && "profile" in result.data) {
      if (feature !== "basic") {
        await store.dispatch("profile/setCurrentProfileByFeature", {
          feature: feature,
          profile: result.data.profile,
        });
      } else {
        await loadProfile(userId);
      }

      return false;
    } else {
      return "Error updating profile";
    }
  } catch (err) {
    return "Error updating profile";
  }
}

async function updateProfileFieldByFeature(
  userId: string,
  feature: string,
  field: string,
  value: string,
  syncTo = "",
  size = 0
): Promise<string | boolean> {
  const postData = [
    {
      position: field,
      sync_to: syncTo,
      value: value,
      font_size: size,
    },
  ];

  try {
    const result = await post(
      "/" + userId + "/" + feature + queryVars(),
      postData
    );

    /** set labels for all sync to fields */
    if ("data" in result && "profile" in result.data) {
      await store.dispatch("profile/setCurrentProfileByFeature", {
        feature: feature,
        profile: result.data.profile,
      });
      return false;
    } else {
      return "Error updating profile";
    }
  } catch (err) {
    return "Error updating profile";
  }
}

async function addProfileFlag(userId: string, flagId: number): Promise<void> {
  const result = await post("/" + userId + "/flags/" + flagId, null).catch(
    () => {
      return false;
    }
  );

  if (result) {
    await getProfileFlags(userId);
  }
}

function formatProfileResponseDataToPost(
  data: KCMPPProfile
): KcmProfilePostData[] {
  const formattedData: KcmProfilePostData[] = [];

  Object.entries(data).forEach(([key, value]: [string, KCMPPField]) => {
    const object: KcmProfilePostData = {
      position: key,
      sync_to: ["hide", "custom"].includes(value.SyncTo) ? "" : value.SyncTo,
      value:
        value.field_type === KCMPPFieldType.STRING || !value.field_type
          ? (value.value as string)
          : "",
      font_size: value.fontSize,
      values:
        value.field_type === KCMPPFieldType.ARRAY
          ? (value.value as KCMPPValuesTableData[])
          : undefined,
      table: value.table,
      field_type: value.field_type,
    };

    formattedData.push(object);
  });

  return formattedData;
}

function styleFontSizeByField(profileField: KCMPPFieldConverted): string {
  return "font-size:" + profileField.fontSize + "px";
}

function uploadCustomIntro(userId: string, file: Blob): Promise<AxiosResponse> {
  const endpoint = HOST + "/" + userId + "/video/upload/intro";

  const formData = new FormData();
  formData.append("upload", file);

  const headers = HEADERS();
  if (!headers.headers || !headers.headers.authorization) {
    return Promise.reject(new Error("No auth token available"));
  }
  if (headers.headers) {
    headers.headers["Content-Type"] = "multipart/form-data";
  }

  return axios.post(endpoint, formData, headers);
}

function uploadCustomOutro(userId: string, file: Blob): Promise<AxiosResponse> {
  const endpoint = HOST + "/" + userId + "/video/upload/outro";

  const formData = new FormData();
  formData.append("upload", file);

  const headers = HEADERS();
  if (!headers.headers || !headers.headers.authorization) {
    return Promise.reject(new Error("No auth token available"));
  }
  if (headers.headers) {
    headers.headers["Content-Type"] = "multipart/form-data";
  }

  return axios.post(endpoint, formData, headers);
}

/**
 * @param file object accessed through an upload input
 * @param fileKey an optional param for passing in a custom file key
 *
 * @return a string that is either the upload location: ie(https://files.keepingcurrentmatters.com/content/images/blahblah/blahblah.jpeg)
 * or S3ERRORS upload catch string
 */
async function uploadScriptImage(
  blob: Blob,
  fileName: string
): Promise<string> {
  const resp = memberImageUpload(
    store.getters["auth/authUserId"],
    `scriptbuilder/${createDirectory()}/${fileName}`,
    blob as Blob
  )
    .then((response) => {
      if (response.status === 200) {
        return response.data.upload;
      } else {
        return uploadFailed;
      }
    })
    .catch(() => {
      return uploadFailed;
    });

  return resp;
}

/**
 * @return a date string with the format: 20220328 - used for creating directories in S3
 */
function createDirectory(): string {
  const date = new Date();
  const dateFolder = date.toISOString().split("T")[0].replace(/-/g, "");
  return dateFolder;
}
