import store from "@/state/store";
import { Route } from "vue-router/types/router";
import { helpers } from "@/services/helpers";
import { Section } from "./tracking.service";
let gtag: typeof window.gtag = null;
let userId = 0;

type GaEventAudiences = "buyers" | "sellers" | "buyers/sellers" | "";

interface GaEvent extends GaEventContent {
  action: string;
  section: Section;
  plan_tier?: string;
  category?: string;
  method?: string;
  query?: string;
  social_platform?: string;
  page_path?: string;
  page_title?: string;
}

interface GaEventContent {
  socialPlatform?: string;
  customization?: string;
  personalization?: string;
  file_extension?: string;
  file_name?: string;
  aspect_ratio?: string;
  slug?: string;
  debug_mode?: boolean;
  // new fields added 6/18/24, use underscores going forward
  content_id?: number;
  content_type?: string;
  content_language?: string;
  content_title?: string;
  content_audience?: GaEventAudiences;
  content_topics?: string;
  content_tags?: string;
  published_date?: string;
  updated_date?: string;
  page?: number;
  categories?: string;
  types?: string;
  query?: string;
  published_since?: string;
  video_length?: string;
  event_target?: string;
}

const GoogleAnalytics = {
  name(): string {
    return "ga4";
  },

  /**
   * Sets up the gtag variable in this file
   */
  init(): void {
    try {
      gtag = window.gtag;
    } catch (err) {
      helpers.customSentryError("Failed to initialize GA", "", {
        error: err,
      });
    }
    this.checkUser();
  },

  async getClientId(): Promise<string> {
    if (gtag === null) {
      this.init();
    }

    return new Promise((resolve) => {
      gtag(
        "get",
        process.env.VUE_APP_GA4_ID,
        "client_id",
        (clientId: unknown) => {
          resolve((clientId as string) || "");
        }
      );
    });
  },

  async getSessionId(): Promise<string> {
    if (gtag === null) {
      this.init();
    }

    return new Promise((resolve) => {
      gtag("get", process.env.VUE_APP_GA4_ID, "session_id", (sId: unknown) => {
        resolve((sId as string) || "");
      });
    });
  },

  checkUser(): void {
    if (userId !== parseInt(store.getters["auth/authUserId"])) {
      userId = parseInt(store.getters["auth/authUserId"]);
      try {
        if (userId === 0) {
          gtag("set", { user_id: null });
          gtag("set", "user_properties", {
            crm_id: null,
          });
        } else {
          gtag("set", { user_id: userId });
          gtag("set", "user_properties", {
            crm_id: userId,
          });
        }
      } catch (err) {
        helpers.customSentryError("Failed to setup user ID with GA", "", {
          error: err,
        });
      }
    }
  },

  event(data: GaEvent): void {
    try {
      if (gtag === null) {
        this.init();
      } else {
        this.checkUser();
      }

      if (window.enableKcmDebug) {
        data.debug_mode = true;
        console.log("GA event: " + data.action + ": ", data);
      }

      gtag("event", data.action, data);
    } catch (err) {
      helpers.customSentryError("Failed to track event with GA", "", {
        error: err,
      });
    }
  },

  /**
   * Tracks a pageview in GA
   * @param to the path that was navigated to
   */
  pageView({ to, ...data }: { to: Route } & GaEvent): void {
    try {
      data.page_path = to.fullPath ?? "";
      data.page_title = to.name ?? "";
      data.action = "page_view";

      this.event(data);
    } catch (err) {
      helpers.customSentryError("Failed to track pageview with GA", "", {
        error: err,
      });
    }
  },
};

export default GoogleAnalytics;
