import { mapGetters, mapActions } from "vuex";
import store from "./store";
import { AuthUserFeature } from "@/types";

export const authComputed = {
  ...mapGetters("auth", [
    "loggedIn",
    "authUser",
    "authToken",
    "authFirstName",
    "authLastName",
    "authEmail",
    "authPhone",
    "authUserId",
    "authStatus",
    "authType",
    "authParentId",
    "authNumberOfChildren",
    "authAccessLevel",
    "authAdmin",
    "authTeamOwner",
    "authIsCrew",
    "getTopLevelFeatureIds",
    "linkedInTokenExpired",
    "linkedInTokenExpiresSoon",
    "isStandardMember",
    "isAllowedReferrals",
    "isTeamOwner",
    "isEnterpriseOwner",
    "isTeamOrEnterpriseOwner",
    "isAnyTeamOwner",
    "isTeamAdmin",
    "getServiceByField",
    "getSocialToken",
    "getFeaturesSlugs",
    "hasFeature",
    "getExpiredFeaturesSlugs",
    "hasExpiredFeature",
    "getPlanSlug",
  ]),
};
export const authMethods = {
  ...mapActions("auth", [
    "login",
    "logout",
    "setCurrentUser",
    "setForceRedirect",
    "setAuthAdmin",
    "swapAuthToActive",
  ]),
};

export const billingComputed = {
  ...mapGetters("billing", [
    "billingStoreService",
    "billingSource",
    "billingSubscription",
    "billingAccount",
    "billingHasPayment",
    "billingPlans",
    "billingHasPendingChange",
    "billingPauseDate",
    "billingState",
    "billingInFreeTrial",
    "billingIsPaused",
    "billingHasPendingPause",
    "billingHasPendingCancel",
    "isMonthly",
    "isLegacy",
    "canConvertToAnnual",
  ]),
};
export const billingMethods = {
  ...mapActions("billing", [
    "setBillingSource",
    "setSubscriptionData",
    "setAccountData",
    "setPlansData",
    "setCouponsData",
    "logout",
  ]),
};

export const contentComputed = {
  ...mapGetters("content", [
    "contentContent",
    "contentCategories",
    "contentLanguages",
    "getContentBySlug",
    "getCategoryByLanguage",
  ]),
};
export const contentMethods = {
  ...mapActions("content", [
    "setContentDataBySlug",
    "setCategoriesByLanguage",
    "setContentLanguages",
  ]),
};

export const notificationMethods = mapActions("notification", [
  "success",
  "error",
  "clear",
]);

export const profileComputed = {
  ...mapGetters("profile", [
    "fullProfile",
    "profileAvatar",
    "profileLogo",
    "profileEmail",
    "profileWebsite",
    "profilePrimaryColor",
    "profilePPCode",
    "profileGuideLink",
    "profileSTMLink",
    "profileSTMVideosLink",
    "profileSTMVideoLink",
    "profileSTMBlogLink",
    "getProfileByFeature",
    "profileSTMLinkExtended",
    "profileAppendPPToSTM",
    "profileHasFlag",
    "hasLocalMarkets",
    "savedLocalMarkets",
    "hasFavoriteMetrics",
    "getFavoriteMetrics",
    "getShowTrendline",
    "getAspectRatio",
    "getDateRange",
  ]),
};

export const settingsComputed = {
  ...mapGetters("settings", [
    "allSettings",
    "languageSetting",
    "contentLanguageSetting",
    "windowHeight",
    "windowWidth",
    "adBlockEnabled",
    "siteConfig",
  ]),
};

export const settingsMethods = mapActions("settings", [
  "changeLoaderSetting",
  "changeLanguageSetting",
  "changeContentLanguageSetting",
  "changeThemeSetting",
  "setWindowDimensions",
  "changeAdBlockEnabled",
  "setSiteConfig",
]);

export function isEligibleTestDrive(): boolean {
  /**
   * Guests should not have access to Test Drive
   */
  if (
    store.getters["billing/billingSource"] === "guest" ||
    store.getters["billing/billingSource"] === "apple"
  ) {
    return false;
  }

  /**
   * Free Trial members should not have access to Test Drive
   */
  if (store.getters["billing/billingInFreeTrial"]) {
    return false;
  }

  /**
   * We currently do not allow guests / team members / enterprise members
   * to start a free trial
   */
  if (store.getters["auth/authType"] === "Guest") {
    return false;
  }

  /**
   * if user has expired feature test-drive, they cannot opt in again
   */

  const authUser = store.getters["auth/authUser"];

  /**
   * If user has free feature, they cannot get test drive
   */
  if (store.getters["auth/hasFeature"]("free")) {
    return false;
  }

  // if the user has no expired features and does not have test drive, they are eligible
  if (
    !authUser.expired_features &&
    !store.getters["auth/hasFeature"]("test-drive")
  ) {
    return true;
    /**
     * if the user has expired features, doesn't have test drive but has the expired feature; we need to check if it the expiration was over a year ago;
     * if so, they are eligible
     * otherwise, not eligible
     */
  } else if (
    authUser.expired_features &&
    !store.getters["auth/hasFeature"]("test-drive") &&
    store.getters["auth/hasExpiredFeature"]("test-drive")
  ) {
    // get expiration date and check to see if year old
    const expirationDate = authUser.expired_features.filter(
      (feature: AuthUserFeature) => feature.slug === "test-drive"
    )[0].expires_at;
    const oneYearAgo = new Date(
      new Date().setFullYear(new Date().getFullYear() - 1)
    );
    if (new Date(expirationDate) <= oneYearAgo) {
      return true;
    }
  }
  return false;
}
