import i18n from "@/i18n";
import { billingService } from "@/services/billingservice/billing.service";
import store from "@/state/store";
import { KcmCouponData, PlanData } from "@/types";
import {
  RecurlyAddOnData,
  RecurlyCouponRedemptionData,
  RecurlyCurrencyData,
  RecurlyPendingChangeData,
  RecurlyPlanData,
  RecurlySubscriptionData,
} from "@/types/recurly.types";
import { DateTimeFormatOptions } from "vue-i18n";

export function billFreq(plan: RecurlyPlanData): string {
  const planNameSplit = planName(plan).split("-");
  return planNameSplit[planNameSplit.length - 1].trim();
}

export function planName(plan: RecurlyPlanData): string {
  if (plan === null || !("name" in plan)) {
    return "no-plan";
  }
  return plan.name;
}

export function billAmount(amount: number): string {
  return "$" + amount.toFixed(2);
}

export function planNameDisplay(plan: RecurlyPlanData): string {
  return planName(plan).split("-")[0].trim();
}

export function planTotalWithAddons(
  subscription: RecurlySubscriptionData | RecurlyPendingChangeData
): number {
  if (subscription.unit_amount) {
    return applyAddons(subscription.add_ons, subscription.unit_amount);
  }
  return applyAddons(subscription.add_ons, 0);
}

export function applyAddons(
  addOns: RecurlyAddOnData[],
  subtotal: number
): number {
  if (!addOns) {
    return subtotal;
  }
  addOns.forEach((addOnData: RecurlyAddOnData) => {
    const val = addOnData.unit_amount;
    subtotal += val;
  });

  return subtotal;
}

export function couponValue(couponData: RecurlyCouponRedemptionData): number {
  if (couponData.coupon.discount.type === "percent") {
    if (!couponData.coupon.discount.percent) {
      return 0;
    }
    return couponData.coupon.discount.percent;
  } else if (couponData.coupon.discount.type === "fixed") {
    return couponData.coupon.discount.currencies[0].amount;
  }
  return 0;
}

export function couponValueDisplay(
  couponData: RecurlyCouponRedemptionData
): string {
  const val = couponValue(couponData);
  if (couponData.coupon.discount.type === "percent") {
    return "-" + val + "%";
  } else if (couponData.coupon.discount.type === "fixed") {
    return "-$" + couponData.coupon.discount.currencies[0].amount;
  } else if (couponData.coupon.discount.type === "free_trial") {
    return (
      couponData.coupon.discount.trial.length +
      " " +
      couponData.coupon.discount.trial.unit +
      " free trial"
    );
  }

  return "invalid coupon type";
}

export function couponExpirationDisplay(
  couponData: RecurlyCouponRedemptionData,
  availableCoupons: KcmCouponData[]
): string {
  const extraCouponData = getAvailableCouponByCode(
    couponData.coupon.code,
    availableCoupons
  );

  if (!extraCouponData) {
    return "There was an issue calculating expiration";
  }
  const createdDate = new Date(couponData.created_at);
  const duration = extraCouponData.duration;

  if (duration === "forever") {
    return "Never";
  } else if (duration === "single_use") {
    return "";
  } else {
    if (extraCouponData.duration.split(" ")[1].includes("month")) {
      createdDate.setMonth(
        createdDate.getMonth() +
          parseInt(extraCouponData.duration.split(" ")[0])
      );
      return formatDate(createdDate);
    }
  }
  return formatDateStr(couponData.coupon.expired_at);
}

export function getAvailableCouponByCode(
  code: string,
  availableCoupons: KcmCouponData[]
): KcmCouponData | null {
  if (availableCoupons === null) {
    return null;
  }
  return availableCoupons.filter((coupon: KcmCouponData) => {
    if (!("code" in coupon)) {
      return false;
    }
    return coupon.code === code;
  })[0];
}

export function checkIfCouponIsSingleUse(
  coupon: RecurlyCouponRedemptionData,
  availableCoupons: KcmCouponData[]
): boolean {
  const couponData = getAvailableCouponByCode(
    coupon.coupon.code,
    availableCoupons
  );
  if (couponData === null) {
    return false;
  }
  return couponData.duration === "single_use";
}

export async function calculateProratedCharge(
  newPlan: string,
  coupon: string
): Promise<number> {
  return billingService.getProratedCharge(
    store.getters["auth/authUserId"],
    newPlan,
    coupon
  );
}

export async function totalCredits(): Promise<number> {
  const accountBalance = await billingService.getAccountBalance();

  const credits = accountBalance?.balances?.filter(
    (balance: RecurlyCurrencyData) => {
      return balance.currency === "USD";
    }
  );

  if (credits && credits.length > 0 && credits[0].amount) {
    return credits[0].amount * -1;
  }

  return 0;
}

export function formatDateStr(dateStr: string): string {
  const date = new Date(dateStr);
  return formatDate(date);
}

export function formatDate(date: Date): string {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  } as DateTimeFormatOptions;
  return date.toLocaleDateString(i18n.locale, options);
}

export class KCMBillingUtils {
  formatDate(date: Date): string {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    } as DateTimeFormatOptions;
    return date.toLocaleDateString(i18n.locale, options);
  }

  formatDateStr(dateStr: string): string {
    const date = new Date(dateStr);
    return this.formatDate(date);
  }

  couponDuration(coupon: KcmCouponData): string {
    if (coupon === null) {
      return "no coupon";
    }
    if (coupon.duration === "forever") {
      return "for life";
    } else if (coupon.duration === "single_use") {
      return "on next bill";
    } else {
      return "for " + coupon.duration;
    }
  }

  calculateCouponValueCopyNoPlan(coupon: KcmCouponData): string {
    if (coupon === null) {
      return "There was a problem with the coupon";
    }

    let couponText = "";

    if (coupon.value.endsWith("%")) {
      couponText = "-" + coupon.value + "/mo";
    } else {
      couponText = "-$" + coupon.value + "/mo";
    }

    couponText += " " + this.couponDuration(coupon);
    return couponText;
  }

  plansWithValidatedCoupon(
    coupon: KcmCouponData,
    plans: PlanData[],
    currentPlan: string
  ): string {
    const validPlans = [];
    for (const [index, plan] of plans.entries()) {
      if (index > this.currentPlanIndex(currentPlan, plans) && coupon.plans) {
        if (coupon.plans === "all") {
          validPlans.push(plan.name);
        } else if (
          coupon.plans.filter((couponPlan: PlanData) => {
            couponPlan.code === plan.code;
          }).length > 0
        ) {
          validPlans.push(plan.name);
        }
      }
    }
    return validPlans.join(",");
  }

  planSlugShort(slug: string): string {
    if (!slug) {
      return "";
    }
    return slug.split("-")[0];
  }

  planNameShort(name: string): string {
    if (!name) {
      return "";
    }
    return name.split(" - ")[0];
  }

  isUpgrade(price: number, plans: PlanData[], currentPlan: string): boolean {
    if (currentPlan === "basic") {
      return price > 29.95;
    } else if (currentPlan === "pro") {
      return price > 49.95;
    } else if (currentPlan === "premium") {
      return price > 89.95;
    }

    const currentPlanData = plans[this.currentPlanIndex(currentPlan, plans)];

    if (!currentPlanData) {
      return false;
    }

    return price > currentPlanData.price ?? true;
  }

  currentPlanIndex(plan: string, plans: PlanData[]): number {
    return plans.findIndex(
      (i: PlanData) =>
        i.slug === plan + "-monthly" || i.slug === plan + "-annual"
    );
  }

  basePrice(plan: PlanData): number {
    let finalPrice = plan.price;
    if (plan.legacy) {
      finalPrice = plan.legacy;
    }
    return finalPrice;
  }

  // taxAmount(plan: PlanData): number {
  //   return plan.tax;
  // }

  couponApplicableToPlan(
    coupon: KcmCouponData,
    plan: PlanData,
    currentPlan: string,
    plans: PlanData[]
  ): boolean {
    const planIndex = plans.indexOf(plan);
    const curPlanIdx = this.currentPlanIndex(currentPlan, plans);
    if (coupon.plans === "all") {
      if (curPlanIdx < planIndex) {
        return true; // member has a plan lower than the proposed plan and coupon is applicable to all plans
      }
      return false; // member has a plan higher than or equal to the proposed plan, no coupon
    }
    if (coupon.plans) {
      if (curPlanIdx < planIndex) {
        return coupon.plans
          .map((couponPlan: PlanData) => {
            return couponPlan.code;
          })
          .includes(plan.code);
      }
    }
    return false;
  }

  calculateCouponValue(plan: PlanData, validatedCoupon: KcmCouponData): number {
    if (validatedCoupon === null) {
      return 0;
    }

    const couponValue = validatedCoupon.value;

    if (couponValue.endsWith("%")) {
      return (parseInt(couponValue.replace("%", "")) / 100) * plan.price;
    } else {
      return parseInt(couponValue);
    }
  }

  applyCouponToPrice(
    plan: PlanData,
    price: number,
    validatedCoupon: KcmCouponData
  ): number {
    if (validatedCoupon === null) {
      return price;
    }
    return price - this.calculateCouponValue(plan, validatedCoupon);
  }

  nextCalculatedBillAmount(
    billingSubscription: RecurlySubscriptionData,
    appliedCoupons: RecurlyCouponRedemptionData[],
    accountBalance: number
  ): number {
    let total =
      this.applyCoupons(
        billingSubscription,
        appliedCoupons,
        planTotalWithAddons(billingSubscription)
      ) - accountBalance;
    if (billingSubscription.tax) total += billingSubscription.tax;
    return total >= 0 ? total : 0;
  }

  calculatedBillAmount(
    billingSubscription: RecurlySubscriptionData,
    appliedCoupons: RecurlyCouponRedemptionData[]
  ): number {
    let total = this.applyCoupons(
      billingSubscription,
      appliedCoupons,
      planTotalWithAddons(billingSubscription)
    );
    if (billingSubscription.tax) total += billingSubscription.tax;
    return total >= 0 ? total : 0;
  }

  calculatedBillAmountDisplay(
    billingSubscription: RecurlySubscriptionData,
    appliedCoupons: RecurlyCouponRedemptionData[]
  ): string {
    return (
      "$" +
      this.calculatedBillAmount(billingSubscription, appliedCoupons).toFixed(2)
    );
  }

  nextCalculatedBillAmountDisplay(
    billingSubscription: RecurlySubscriptionData,
    appliedCoupons: RecurlyCouponRedemptionData[],
    accountBalance: number
  ): string {
    return (
      "$" +
      this.nextCalculatedBillAmount(
        billingSubscription,
        appliedCoupons,
        accountBalance
      ).toFixed(2)
    );
  }

  applyCoupons(
    billingSubscription: RecurlySubscriptionData,
    appliedCoupons: RecurlyCouponRedemptionData[],
    subtotal: number
  ): number {
    appliedCoupons.forEach((couponData: RecurlyCouponRedemptionData) => {
      const val = couponValue(couponData);
      if (couponData.coupon.discount.type === "percent") {
        const discount = planTotalWithAddons(billingSubscription) * (val / 100);
        subtotal -= discount;
      } else if (couponData.coupon.discount.type === "fixed") {
        subtotal -= couponData.coupon.discount.currencies[0].amount;
      }
    });

    return subtotal;
  }

  nextBillDate(
    billingSubscription: RecurlySubscriptionData,
    billingInFreeTrial: boolean
  ): string {
    if (
      billingSubscription === null ||
      !("current_term_ends_at" in billingSubscription)
    ) {
      return "";
    }

    if (billingInFreeTrial) {
      return this.formatDateStr(billingSubscription.trial_ends_at);
    }

    return this.formatDateStr(billingSubscription.current_term_ends_at);
  }

  monthDiff(dateFrom: Date, dateTo: Date): number {
    return Math.abs(
      dateTo.getMonth() -
        dateFrom.getMonth() +
        12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }

  pauseLength(billingSubscription: RecurlySubscriptionData): number {
    return this.monthDiff(
      new Date(billingSubscription.current_term_ends_at),
      new Date(billingSubscription.paused_at)
    );
  }
}
