import {
  KcmCategory,
  KcmContent,
  KcmStoredCategories,
  KcmStoredContent,
} from "@/types/index";
import { ContentStore } from "@/types/index";
import { Commit } from "vuex";
import { saveToLocalStorage } from "@/composables/helperUtils";

const getDefaultState = (): ContentStore => {
  return {
    content: JSON.parse(
      window.sessionStorage.getItem("content.content") || "{}"
    ),
    categories: JSON.parse(
      window.sessionStorage.getItem("content.categories") || "{}"
    ),
  };
};

export const state = getDefaultState();

export const getters = {
  contentContent(state: ContentStore): KcmStoredContent {
    return state.content;
  },
  contentCategories(state: ContentStore): KcmStoredCategories {
    return state.categories;
  },
  getContentBySlug:
    (state: ContentStore) =>
    (slug: string, language = "en"): KcmContent | null => {
      if (
        state.content &&
        state.content[slug] &&
        state.content[slug][language]
      ) {
        return state.content[slug][language];
      }
      return null;
    },
  getCategoryByLanguage:
    (state: ContentStore) =>
    (language = "en"): KcmCategory[] | null => {
      if (state.categories && state.categories[language]) {
        return state.categories[language];
      }
      return null;
    },
};

export const actions = {
  setContentDataBySlug(
    { commit }: { commit: Commit },
    newValue: { content: KcmContent; language: string }
  ): void {
    commit("SET_CONTENT_DATA_BY_SLUG", newValue);
  },
  setCategoriesByLanguage(
    { commit }: { commit: Commit },
    newValue: { categories: KcmCategory[]; language: string }
  ): void {
    commit("SET_CATEGORIES", newValue);
  },
};

export const mutations = {
  SET_CONTENT_DATA_BY_SLUG(
    state: ContentStore,
    newValue: { content: KcmContent; language: string }
  ): void {
    if (!state.content[newValue.content.slug]) {
      state.content[newValue.content.slug] = {};
    }
    state.content[newValue.content.slug][newValue.language] = newValue.content;
    saveToLocalStorage("content.content", state.content);
  },
  SET_CATEGORIES(
    state: ContentStore,
    newValue: { categories: KcmCategory[]; language: string }
  ): void {
    state.categories[newValue.language] = newValue.categories;
    saveToLocalStorage("content.categories", state.categories);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
