import { RouteConfig } from "vue-router";

import basicRoutes from "@/router/routes/basicPages";
import { contentRoutes } from "@/router/routes/shareableContent";
import { marketUpdateRoutes } from "@/router/routes/marketUpdates";
import { profileRoutes } from "@/router/routes/profile";
import { accountRoutes } from "@/router/routes/account";
import resourcesRoutes from "@/router/routes/resources";
import { learningCenterRoutes } from "@/router/routes/learningCenter";

const routes2: RouteConfig[] = [
  ...basicRoutes,
  ...contentRoutes["2"],
  ...marketUpdateRoutes["2"],
  ...profileRoutes["2"],
  ...accountRoutes["2"],
  ...resourcesRoutes,
  ...learningCenterRoutes["2"],

  {
    path: "/social-accounts/scheduled-facebook-posts",
    name: "Scheduled Facebook Posts",
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/settings/scheduled-facebook-posts.vue"),
  },
  {
    path: "/email-builder",
    name: "Email Builder",
    meta: { authRequired: true, featureRequired: "email-builder" },
    component: () =>
      import("@/router/views/personalizedContent/email-builder-page.vue"),
  },
  { path: "/email", redirect: "/email-builder" },
  { path: "/newsletter", redirect: "/email-builder" },

  {
    path: "/local",
    alias: "/local-promo",
    name: "Your Local Market",
    meta: { authRequired: true },
    component: () => import("@/router/views/local/local-page.vue"),
  },
  {
    path: "/content-calendar",
    name: "Content Calendar",
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/personalizedContent/content-calendar.vue"),
  },
];

export default routes2;
