
import { defineComponent } from "vue";
/**
 * Preloader component
 */
export default defineComponent({
  name: "LoaderWidget",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "",
    },
    textTop: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    noRow: {
      type: Boolean,
      default: false,
    },
  },
});
