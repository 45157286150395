import { KcmContent } from "@/types";
import { ref, computed } from "vue";
import { contentService } from "@/services/internal-api/content.service";

const alertContent = ref<KcmContent[]>([]);
const lastChecked = ref<number>(0);

const alertData = computed((): KcmContent[] => {
  return alertContent.value.filter((alert) => {
    return !cookies.value.some((item) =>
      item.includes(`alert-${alert.id}-hidden=true`)
    );
  });
});

const cookies = computed((): string[] => {
  return cookieState.value;
});

const cookieState = ref(document.cookie.split(";"));

const updateCookies = () => {
  cookieState.value = document.cookie.split(";");
};

/**
 * If current alert content is over 15 minutes old OR
 * the force update is set to true, fetch the most recent published
 * alert content from the content API and update the lastUpdated timestamp
 * @param forceUpdate
 * @returns
 */
async function updateAlertData(forceUpdate: boolean = false): Promise<void> {
  const fifteenMinutes = 15 * 60 * 1000;

  if (forceUpdate || Date.now() - lastChecked.value > fifteenMinutes) {
    try {
      const alerts = await contentService.getLatestAlertContent();
      if (alerts) {
        alertContent.value = alerts;
        lastChecked.value = Date.now();
      }
    } catch (err) {
      console.log("Failed to get Alerts: ", err);
    }
  }
}

function dismissAlert(id: string, expiredAt: string | null | undefined): void {
  const oneWeek = 7 * 24 * 60 * 60 * 1000;
  const expireDate = new Date(expiredAt ?? Date.now() + oneWeek).toUTCString();
  document.cookie = `alert-${id}-hidden=true; expires=${expireDate}; secure; SameSite=Strict;`;
  updateCookies();
}

export { alertData, updateAlertData, dismissAlert };
