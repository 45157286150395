export enum KCMPPFeature {
  BASIC = "basic",
  BLOG = "blog",
  GRAPHICS = "graphics",
  GUIDES = "guides",
  LOCAL = "local",
  NEWSLETTER = "newsletter",
  VIDEO = "video",
}

export enum KCMPPFieldType {
  STRING = "string",
  ARRAY = "array",
}

export type KCMPPLastUpdate = {
  [x in KCMPPFeature]?: number;
};

export interface KCMPPProfilesResponse {
  data: {
    last_update: KCMPPLastUpdate;
    profiles: KCMPPProfiles;
  };
}

export type KCMPPProfiles = {
  [x in KCMPPFeature]?: KCMPPProfile;
};

export interface KCMPPProfile {
  [key: string]: KCMPPField;
}

export interface KCMPPProfileConverted {
  [key: string]: KCMPPFieldConverted;
}

export interface KCMPPFieldMinimal {
  SyncTo: string;
  fontSize: number;
}

export interface KCMPPField extends KCMPPFieldMinimal {
  value: string | KCMPPValuesTableData[];
  field_type: KCMPPFieldType;
  table: KCMPPValuesTable;
}

/**
 * KCMPPFieldConverted is intended for use with front end inputs such as checkboxes
 *
 * translateFromAPI in the profileUtils composable converts values as necessary from strings to booleans or json objects
 * translateToAPI in the profileUtils composable does the opposite, converting the values back to 0/1 or yes/no strings (one day we'll clean these up)
 */
export interface KCMPPFieldConverted extends KCMPPFieldMinimal {
  value:
    | string
    | boolean
    | { [x: string]: string | boolean }
    | Record<string, string>[];
  field_type?: string;
  table?: string;
  values?: Record<string, string>[];
}

// Feature specific table related taypes
export enum KCMPPValuesTable {
  USER_MARKETS = "users_markets",
  EMPTY = "",
}

export type KCMPPValuesTableData = KCMPPLocalData;

export interface KCMPPLocalData {
  market_ids: string;
  market_name: string;
  market_zips: string;
}

// Flag types
export interface KCMPPFlagsResponse {
  data: {
    last_update: KCMPPLastUpdate;
    flags: KCMPPMemberFlag[];
  };
}

export interface KCMPPMemberFlag {
  first_added_at: string;
  last_added_at: string;
  flag: KCMPPFlag;
}

export interface KCMPPFlag {
  name: string;
  id: number;
  created_at: string;
}

export interface KCM_PP_FE_StringField extends KCMPPFieldConverted {
  field_type: "string";
  value: string;
}

export interface KCM_PP_FE_BooleanField extends KCMPPFieldConverted {
  field_type: "string";
  value: boolean;
}

export interface KCM_PP_FE_EnabledPages extends KCMPPFieldConverted {
  field_type: "string";
  value: {
    buyer: boolean;
    seller: boolean;
    fthb: boolean;
  };
}

type PosKey = `pos_${string}`;
type PosFields = {
  [key in PosKey]: KCM_PP_FE_StringField;
};

type PosFrontKey = `pos_${string}_front`;
type PosFrontFields = {
  [key in PosFrontKey]: KCM_PP_FE_StringField;
};

type BasicFields =
  | "bio"
  | "city"
  | "compliance"
  | "designations"
  | "email"
  | "facebook"
  | "first_name"
  | "full_name"
  | "google"
  | "instagram"
  | "job_title"
  | "last_name"
  | "linkedin"
  | "location"
  | "logo"
  | "phone"
  | "photo"
  | "pinterest"
  | "pp_code"
  | "primary_color"
  | "state"
  | "twitter"
  | "website"
  | "youtube";

export type KCM_PP_FE_Basic = KCMPPProfileConverted & {
  [K in BasicFields]: KCM_PP_FE_StringField;
};

type BlogStringFields =
  | "headertext"
  | "fbpixel"
  | "lead_email"
  | "logo_pos"
  | "prev_posted"
  | "privacy_policy"
  | "privacy_policysp";

type BlogBooleanFields = "lead_capture" | "prev_posted";

export type KCM_PP_FE_Blog = PosFields &
  KCMPPProfileConverted & {
    [K in BlogStringFields]: KCM_PP_FE_StringField;
  } & {
    [K in BlogBooleanFields]: KCM_PP_FE_BooleanField;
  };

export type KCM_PP_FE_Videos = PosFields &
  KCMPPProfileConverted & {
    custom_intro: KCM_PP_FE_BooleanField;
    custom_outro: KCM_PP_FE_BooleanField;
  };

type GraphicsStringFields = "bgcolor";

type GraphicsBooleanFields =
  | "ehlogo"
  | "eh_lenderlogo"
  | "realtorlogo"
  | "showlogo";

export type KCM_PP_FE_Graphics = PosFields &
  KCMPPProfileConverted & {
    [K in GraphicsStringFields]: KCM_PP_FE_StringField;
  } & {
    [K in GraphicsBooleanFields]: KCM_PP_FE_BooleanField;
  };

type GuidesStringFields =
  | "buyer_link"
  | "buyersp_link"
  | "seller_link"
  | "sellersp_link"
  | "fthb_link"
  | "fthbsp_link";

type GuidesBooleanFields = "ehlogo" | "eh_lenderlogo" | "front_customization";

export type KCM_PP_FE_Guides = PosFields &
  PosFrontFields &
  KCMPPProfileConverted & {
    [K in GuidesStringFields]: KCM_PP_FE_StringField;
  } & {
    [K in GuidesBooleanFields]: KCM_PP_FE_BooleanField;
  };

type NewsletterStringFields =
  | "bgcolor"
  | "guides_location"
  | "headertext"
  | "language"
  | "logo_pos"
  | "ppbackground"
  | "pplocation"
  | "subtitletext";

type NewsletterBooleanFields =
  | "buyerad"
  | "color_background"
  | "eh_lenderlogo"
  | "ehlogo"
  | "realtorlogo"
  | "sellerad"
  | "show_footer"
  | "show_header"
  | "show_sidebar";

export type KCM_PP_FE_Newsletter = PosFields &
  KCMPPProfileConverted & {
    [K in NewsletterStringFields]: KCM_PP_FE_StringField;
  } & {
    [K in NewsletterBooleanFields]: KCM_PP_FE_BooleanField;
  };
