import store from "@/state/store";
import { AuthUser } from "@/types";
import { Userpilot } from "userpilot";

const UserpilotTracker = {
  name(): string {
    return "userpilot";
  },

  init(): void {
    if (!process.env.VUE_APP_USERPILOT_TOKEN) {
      return;
    }

    Userpilot.initialize(process.env.VUE_APP_USERPILOT_TOKEN);
  },

  async identify(user: Partial<AuthUser>): Promise<void> {
    if (!user.id) {
      return;
    }

    const userPlanFeature = store.getters["auth/getPlanSlug"];

    const userProperties = {
      name: `${user.first_name} ${user.last_name}`,
      member_id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      plan_tier: userPlanFeature,
    };

    await this.updateUserAttributes(userProperties);
  },

  async updateUserAttributes(data: Record<string, unknown>): Promise<void> {
    const userId = store.getters["auth/authUserId"];

    if (window.enableKcmDebug) {
      console.log(`Userpilot user property update: ${userId}: `, data);
    }

    Userpilot.identify(userId, data);
  },

  event(name: string, data?: Record<string, string>): void {
    if (window.enableKcmDebug) {
      console.log(`Userpilot event: ${name}: `, data);
    }

    Userpilot.track(name.toLowerCase(), data);
  },

  pageView(): void {
    Userpilot.reload();
  },
};

export default UserpilotTracker;
