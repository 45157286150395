/* eslint-disable */
import { KcmTracker } from "./../trackingservice/tracking.service";
import { KcmContent, KcmNetworkOptions, KcmServiceData } from "@/types";
import { appleService } from "./apple.service";
import { facebookService } from "./facebook.service";
import { googleService } from "./google.service";
import { instagramService } from "./instagram.service";
import { linkedinService } from "./linkedin.service";
import { twitterService } from "./twitter.service";
import { pinterestService } from "./pinterest.service";
import { userService } from "@/services/internal-api/auth.service";
import store from "@/state/store";
import TrackingService, {
  KcmContentEvent,
} from "@/services/trackingservice/tracking.service";
import {
  KcmSocialPromise,
  SocialMediaServiceKey,
} from "@/types/social-media.types";

// this wrapper service can be used to perform any generic behavior before calling the social media specific service function

const services = {
  facebook: facebookService,
  instagram: instagramService,
  twitter: twitterService,
  linkedin: linkedinService,
  google: googleService,
  apple: appleService,
  pinterest: pinterestService,
};

const industryServiceData: { [x: string]: { [x: string]: KcmServiceData } } = {
  "1": {
    facebook: {
      title: "Facebook Business",
      slug: "facebook",
      icon: "facebook",
      canSchedule: true,
      connectMsg:
        "Changing your Facebook password will require reconnecting with MyKCM.",
      disconnectMsg:
        "Disconnecting from Facebook will also remove any connection between MyKCM and Instagram.",
      disabledContentTypes: {},
      login: true,
    },
    instagram: {
      title: "Instagram Business",
      slug: "instagram",
      icon: "instagram",
      connectMsg:
        "Connecting MyKCM to an Instagram Business page requires authentication via Facebook.",
      disabledContentTypes: {},
      upsell: {
        infographics:
          "KCM Infographics are not designed for Instagram, however our Social Graphics are the perfect alternative.<br/><br/><a href='/socialgraphics/'>Click here</a> to share your latest social graphics.",
      },
      login: false,
    },
    twitter: {
      title: "Twitter",
      icon: "x-twitter",
      slug: "twitter",
      disabledContentTypes: {},
      login: false,
    },
    linkedin: {
      title: "LinkedIn",
      slug: "linkedin",
      icon: "linkedin",
      canExpire: true,
      connectMsg:
        "LinkedIn limits access to 60 days. KCM will send you a reminder via email one week before expiration.",
      disabledContentTypes: {},
      login: false,
    },
    google: {
      title: "Google",
      slug: "google",
      icon: "google",
      connectMsg:
        "If you have a Google account, you can use that to quickly and securely login to MyKCM.",
      disabledContentTypes: {},
      login: true,
    },
    apple: {
      title: "Apple",
      slug: "apple",
      icon: "apple",
      connectMsg:
        "If you have an Apple ID, you can use that to quickly and securely login to MyKCM.",
      disabledContentTypes: {},
      login: true,
    },
  },
  "2": {
    facebook: {
      title: "Facebook Business",
      slug: "facebook",
      icon: "facebook",
      canSchedule: true,
      connectMsg:
        "Changing your Facebook password will require reconnecting with Bridge Builders.",
      disconnectMsg:
        "Disconnecting from Facebook will also remove any connection between Bridge Builders and Instagram.",
      disabledContentTypes: {},
      login: true,
    },
    instagram: {
      title: "Instagram Business",
      slug: "instagram",
      icon: "instagram",
      connectMsg:
        "Connecting Bridge Builders to an Instagram Business page requires authentication via Facebook.",
      disabledContentTypes: {},
      upsell: {
        infographics:
          "KCM Infographics are not designed for Instagram, however our Social Graphics are the perfect alternative.<br/><br/><a href='/socialgraphics/'>Click here</a> to share your latest social graphics.",
      },
      login: false,
    },
    linkedin: {
      title: "LinkedIn",
      slug: "linkedin",
      icon: "linkedin",
      canExpire: true,
      connectMsg:
        "LinkedIn limits access to 60 days. KCM will send you a reminder via email one week before expiration.",
      disabledContentTypes: {},
      login: false,
    },
    google: {
      title: "Google",
      slug: "google",
      icon: "google",
      connectMsg:
        "If you have a Google account, you can use that to quickly and securely login to Bridge Builders.",
      disabledContentTypes: {},
      login: true,
    },
  },
};

const serviceData = industryServiceData[process.env.VUE_APP_INDUSTRY_ID ?? "1"];

const pinterestData: KcmServiceData = {
  title: "Pinterest",
  slug: "pinterest",
  icon: "pinterest",
  disabledContentTypes: {},
  login: false,
};

const shareMedias = {
  blog: {
    share: [
      serviceData.facebook,
      serviceData.twitter,
      serviceData.linkedin,
      pinterestData,
      serviceData.instagram,
    ],
    noShare: [],
    schedule: [serviceData.facebook],
  },
  videos: {
    share: [
      serviceData.facebook,
      serviceData.twitter,
      serviceData.linkedin,
      pinterestData,
      serviceData.instagram,
    ],
    noShare: [],
    schedule: [serviceData.facebook],
  },
  "social-graphics-single": {
    share: [
      serviceData.facebook,
      serviceData.twitter,
      serviceData.linkedin,
      serviceData.instagram,
    ],
    noShare: [],
    schedule: [serviceData.facebook],
  },
  "social-graphics-multi": {
    share: [
      serviceData.facebook,
      serviceData.twitter,
      serviceData.linkedin,
      serviceData.instagram,
    ],
    noShare: [],
    schedule: [serviceData.facebook],
  },
  infographics: {
    share: [serviceData.facebook, serviceData.twitter, serviceData.linkedin],
    noShare: [serviceData.instagram],
    schedule: [serviceData.facebook],
  },
  slide: {
    share: [
      serviceData.facebook,
      serviceData.twitter,
      serviceData.linkedin,
      serviceData.instagram,
    ],
    noShare: [],
    schedule: [serviceData.facebook],
  },
  mmr: {
    share: [
      serviceData.facebook,
      serviceData.twitter,
      serviceData.linkedin,
      serviceData.instagram,
    ],
    noShare: [],
    schedule: [serviceData.facebook],
  },
  local: {
    share: [
      serviceData.facebook,
      serviceData.twitter,
      serviceData.linkedin,
      serviceData.instagram,
    ],
    noShare: [],
    schedule: [serviceData.facebook],
  },
};

export const socialMediaService = {
  login,
  init,
  connect,
  disconnect,
  post,
  post_v2,
  update,
  remove,
  getPages,
  checkAccess,
  services,
  serviceData,
  shareMedias,
};

function checkAccess(
  service: (typeof services)[SocialMediaServiceKey]
): boolean {
  return service.checkAccess();
}

async function login(
  service: (typeof services)[SocialMediaServiceKey]
): Promise<KcmSocialPromise> {
  return await service.login().then((response: KcmSocialPromise) => {
    return response;
  });
}
async function init(
  service: (typeof services)[SocialMediaServiceKey]
): Promise<boolean> {
  return await service.init();
}
async function connect(
  service: (typeof services)[SocialMediaServiceKey]
): Promise<KcmSocialPromise> {
  return await service.connect().then(async (response: KcmSocialPromise) => {
    if (response) {
      if (response.success && !response.data) {
        return {
          success: true,
          data: "",
        };
      }
      if (response.success && response.data) {
        TrackingService.trackEvent("connectedSocial", { media: service.name });

        // if we're connecting to IG, save to API as FB token
        let serviceName = service.name;
        if (serviceName === "instagram") {
          serviceName = "facebook";
        }

        const apiResp = await userService.updateServiceData(
          store.getters["auth/authUserId"],
          serviceName,
          response.data
        );

        // update page names
        await getPages(service, true);

        if (apiResp) {
          return {
            success: false,
            data: apiResp,
          };
        }
        return response;
      }
    }
    return {
      success: false,
      data: "Something went wrong",
    };
  });
}
async function disconnect(
  service: (typeof services)[SocialMediaServiceKey]
): Promise<KcmSocialPromise> {
  const serviceName = service.name;
  if (serviceName === "instagram") {
    const facebookData = store.getters["auth/getSocialToken"]("facebook");
    const apiResp = await userService.updateServiceData(
      store.getters["auth/authUserId"],
      "facebook",
      {
        token: facebookData.token,
        id: facebookData.id,
        scope: facebookService.scopes,
      }
    );
    if (apiResp) {
      return {
        success: false,
        data: apiResp,
      };
    }
  } else {
    const apiResp = await userService.removeServiceData(
      store.getters["auth/authUserId"],
      serviceName
    );
    if (apiResp) {
      service.serviceData.pages = [];
      return {
        success: false,
        data: apiResp,
      };
    }
  }
  return await service.disconnect().then((response: KcmSocialPromise) => {
    service.serviceData.pages = [];
    return response;
  });
}
async function post(
  service: (typeof services)[SocialMediaServiceKey],
  content: KcmContent,
  contentType: string,
  networkOptions?: KcmNetworkOptions
): Promise<KcmSocialPromise> {
  //send this share event to our trackers
  const data: KcmContentEvent = {
    content: content,
    contentType: contentType,
    method: service.name,
  };

  let eventType = "sharedContent";
  if (networkOptions?.schedule_time) {
    eventType = "scheduledContent";
  }

  if (networkOptions?.video_length) {
    data.videoLength = networkOptions.video_length;
  }

  TrackingService.trackEvent(eventType as keyof KcmTracker, data);

  return await service.post(content, contentType, networkOptions);
}

async function post_v2(
  service: (typeof services)[SocialMediaServiceKey],
  content: KcmContent,
  networkOptions?: KcmNetworkOptions
): Promise<KcmSocialPromise> {
  //send this share event to our trackers
  const data: KcmContentEvent = {
    content: structuredClone(content),
    contentType: content.content_type,
    method: service.name,
  };
  let eventType = "sharedContent";
  if (networkOptions?.schedule_time) {
    eventType = "scheduledContent";
  }

  if (networkOptions?.video_length) {
    data.videoLength = networkOptions.video_length;
  }

  if (networkOptions?.aspect_ratio) {
    data.aspectRatio = networkOptions.aspect_ratio;
  }

  TrackingService.trackEvent(eventType as keyof KcmTracker, data);

  return await service.post(content, content.content_type, networkOptions);
}

async function update(
  service: (typeof services)[SocialMediaServiceKey],
  networkOptions?: KcmNetworkOptions
): Promise<KcmSocialPromise> {
  return await service.update(networkOptions);
}

async function remove(
  service: (typeof services)[SocialMediaServiceKey],
  networkOptions?: KcmNetworkOptions
): Promise<KcmSocialPromise> {
  return await service.remove(networkOptions);
}

async function getPages(
  service: (typeof services)[SocialMediaServiceKey],
  breakCache?: boolean
): Promise<KcmSocialPromise> {
  if (service.serviceData.pages.length > 0 && !breakCache) {
    return { success: true, data: service.serviceData.pages };
  }
  const pages = await service.getPages();
  return pages;
}
