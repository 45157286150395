import { helpers } from "@/services/helpers";
import store from "@/state/store";
import { KcmSocialPromise, ServiceData } from "@/types/social-media.types";

const clientID = process.env.VUE_APP_APPLE_CLIENT_ID;

const serviceData: ServiceData = {
  initiated: false,
  pages: [],
};

export const appleService = {
  name: "apple",
  login,
  init,
  connect,
  disconnect,
  post,
  update,
  remove,
  getPages,
  checkAccess,
  serviceData,
};

function checkAccess(): boolean {
  const hasToken = store.getters["auth/getSocialToken"]("apple");
  return hasToken !== null;
}

async function login(): Promise<KcmSocialPromise> {
  try {
    const response = await window.AppleID.auth.signIn();
    if (response.error) {
      return {
        success: false,
        error: response.error,
      };
    }
    if (!response.authorization || !response.authorization.id_token) {
      return {
        success: false,
        error: "Apple Sign In Failed - No Token returned from Apple",
      };
    }
    return {
      success: true,
      data: helpers.parseJwt(response.authorization.id_token).sub,
    };
  } catch (e) {
    return {
      success: false,
      error: "Apple Sign In Failed - No Token returned from Apple",
    };
  }
}

async function init(): Promise<boolean> {
  const getUrl = window.location;
  const baseUrl = getUrl.protocol + "//" + getUrl.host;

  window.AppleID.auth.init({
    clientId: clientID,
    scope: "",
    redirectURI: baseUrl,
    state: "Member Login",
    usePopup: true,
  });

  serviceData.initiated = true;
  return serviceData.initiated;
}

async function connect(): Promise<KcmSocialPromise> {
  try {
    const result = await window.AppleID.auth.signIn();
    if (result.authorization && result.authorization.id_token) {
      const token = helpers.parseJwt(result.authorization.id_token)["sub"];
      return {
        success: true,
        data: { id: token },
      };
    }
    if (result.error) {
      return {
        success: false,
        error: result.error,
      };
    }
    return {
      success: false,
      error: "Apple Authentication Failed",
    };
  } catch (e) {
    return {
      success: false,
      error: "Apple Authentication Failed",
    };
  }
}

async function disconnect(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    // TODO need to hit a backend service to revoke token access from Apple, can't put secret in the front end
    // https://developer.apple.com/documentation/sign_in_with_apple/revoke_tokens
    resolve({ success: true });
  });
}

async function post(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No post for apple",
    });
  });
}

async function update(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No update for apple",
    });
  });
}

async function remove(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No remove for apple",
    });
  });
}

async function getPages(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No function to get pages for apple",
    });
  });
}
