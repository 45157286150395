import { Commit } from "vuex";

export const state = {
  type: "",
  message: "",
};

export const mutations = {
  success(state: { type: string; message: string }, message: string): void {
    state.type = "alert-success";
    state.message = message;
  },
  error(state: { type: string; message: string }, message: string): void {
    state.type = "alert-danger";
    state.message = message;
  },
  clear(state: { type: string; message: string }): void {
    state.type = "";
    state.message = "";
  },
};

export const actions = {
  success({ commit }: { commit: Commit }, message: string): void {
    commit("success", message);
  },
  error({ commit }: { commit: Commit }, message: string): void {
    commit("error", message);
  },
  clear({ commit }: { commit: Commit }): void {
    commit("clear");
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
