import store from "@/state/store";
import { KcmSocialPromise, ServiceData } from "@/types/social-media.types";

const clientID =
  process.env.VUE_APP_GOOGLE_CLIENT_ID + ".apps.googleusercontent.com";

const serviceData: ServiceData = {
  initiated: false,
  pages: [],
};
export const googleService = {
  name: "google",
  login,
  init,
  connect,
  disconnect,
  post,
  update,
  remove,
  getPages,
  checkAccess,
  serviceData,
};

function checkAccess(): boolean {
  const hasToken = store.getters["auth/getSocialToken"]("google");
  return hasToken !== null;
}

async function login(): Promise<KcmSocialPromise> {
  try {
    const result = await gapi.auth2.getAuthInstance().signIn();
    const id = result.getId();
    return {
      success: true,
      data: id,
    };
  } catch (e) {
    return {
      success: false,
      error: "Google Sign In Failed - No Token returned from Google",
    };
  }
}

async function init(): Promise<boolean> {
  if (!window.gapi || !window.gapi.auth2) {
    return false;
  }

  window.gapi.auth2.init({
    client_id: clientID,
    cookie_policy: "single_host_origin",
    plugin_name: "MyKCM Login",
  });

  serviceData.initiated = true;
  return serviceData.initiated;
}

async function connect(): Promise<KcmSocialPromise> {
  try {
    const result = await gapi.auth2.getAuthInstance().signIn();
    const id = result.getId();
    return {
      success: true,
      data: { id: id },
    };
  } catch (e) {
    return {
      success: false,
      error: "Google Authentication Failed",
    };
  }
}

async function disconnect(): Promise<KcmSocialPromise> {
  await gapi.auth2.getAuthInstance().disconnect();
  return { success: true };
}

async function post(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No post for google",
    });
  });
}

async function update(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No update for google",
    });
  });
}

async function remove(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No remove for google",
    });
  });
}

async function getPages(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    resolve({
      success: false,
      error: "No function to get pages for google",
    });
  });
}
