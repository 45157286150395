
import { settingsComputed } from "@/state/helpers";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BackToTop",
  data() {
    return {
      scTimer: 0,
      scY: 0,
      showArrowHeight: 300,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.setArrowHeight();
  },
  computed: {
    ...settingsComputed,
  },
  methods: {
    handleScroll(): void {
      if (this.scTimer) return;
      this.scTimer = window.setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop(): void {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    setArrowHeight(): void {
      this.showArrowHeight = window.innerHeight;
    },
  },
  watch: {
    windowHeight() {
      this.setArrowHeight();
    },
  },
});
