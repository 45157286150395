export const fbAccessScopes = [
  "pages_show_list", // needed to get information about Facebook pages
  "public_profile", // needed to get information about Facebook profile
  "business_management", // needed to get information about Facebook Business pages
];
export const fbPostingScopes = ["pages_read_engagement", "pages_manage_posts"]; // both needed to share/schedule to Facebook
export const facebookScopes = fbAccessScopes.concat(fbPostingScopes);

export const igAccessScopes = [
  "instagram_basic", // needed to get information about the Instagram profile
  "instagram_content_publish", // needed to share to Instagram
];
