import Redx from "@/components/member-perks/modal-sections/redx.vue";
import BonusPerk from "@/components/member-perks/modal-sections/bonus-perk.vue";
import MemberReferral from "@/components/member-perks/modal-sections/member-referral.vue";
import { contentService } from "@/services/internal-api/content.service";
import { KcmContent, KcmContentArrayResult } from "@/types";
import { computed, ref } from "vue";

export interface Perk {
  header: string;
  description: string;
  slug: string;
  logo: string;
  color: string;
  component: typeof Redx | typeof BonusPerk | typeof MemberReferral;
  standardOnly: boolean;
  contents?: string;
  featuredImage?: string;
  download?: string;
  content_type?: string;
}

const staticPerks: Perk[] = [
  {
    header: "REDX",
    slug: "redx",
    description:
      "<p>Generate new business with REDX, the all-in-one platform connecting agents with vetted buyers and sellers. <p /> <p>Save 15% on any new subscription and get $150 off with a waived setup fee.<p />",
    logo: require("@/assets/images/partners/redx/redx.png"),
    color: "#ca1600",
    component: Redx,
    standardOnly: false,
  },
  {
    header: "KCM Referral Program",
    slug: "referrals",
    description:
      "<p>Loving your KCM Membership? Refer a friend and get a free month of Membership! <p /> <p>Click here to grab your referral code and learn more.<p />",
    logo: require("@/assets/industry/images/logo.svg"),
    color: "#00aeef",
    component: MemberReferral,
    standardOnly: true,
  },
];

const loadedPerks = ref<Perk[]>([]);

export const perks = computed(() => [...staticPerks, ...loadedPerks.value]);

export function hasPerk(perkSlug: string): Perk | undefined {
  return perks.value.find((perk) => perk.slug === perkSlug);
}

/**
 *
 * Get perks within the default query limit for a given page.
 *
 */
export async function getPerks(page = 0): Promise<KcmContentArrayResult> {
  try {
    return await contentService.getContents({
      types: "pdf",
      page,
    });
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error("Failed to get perks");
  }
}

/**
 *
 * Recursive call to getPerks() that gets all perks
 * from the Content API with no limit imposed on the
 * number of returned values.
 *
 * Accepts an optional fourth parameter of a perk slug
 * which will short circuit the recursive call and returns
 * the perk from the haystack.
 *
 */
export async function getAllPerks(
  hasMore = true,
  page = 0,
  perkResults: KcmContent[] = [],
  targetSlug?: string
): Promise<Perk | undefined> {
  if (!hasMore) {
    return;
  }
  try {
    const { content, has_more, error } = await getPerks(page);

    if (content && !error) {
      const mergedPerks = [...perkResults, ...content];

      setPerks(mergedPerks);

      if (targetSlug) {
        const foundPerk = hasPerk(targetSlug);
        if (foundPerk) {
          return foundPerk;
        }
      }

      return getAllPerks(has_more, (page += 1), mergedPerks, targetSlug);
    } else {
      loadedPerks.value = [];
      return;
    }
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }

    throw new Error("Failed to get all perks");
  }
}

const setPerks = (content: KcmContent[]): Perk[] => {
  const newPerks: Perk[] = [];
  content.forEach((pdf: KcmContent) => {
    const perk: Perk = {
      header: pdf.title,
      slug: pdf.slug,
      description: pdf.description,
      logo: require("@/assets/industry/images/logo.svg"),
      color: "#00aeef",
      component: BonusPerk,
      standardOnly: false,
      contents: pdf.contents,
      featuredImage: require("@/assets/industry/images/logo-white.svg"),
      download: pdf.assets ? pdf.assets[0].url : "",
      content_type: pdf.content_type,
    };
    newPerks.push(perk);
  });

  loadedPerks.value = [...newPerks];

  return loadedPerks.value;
};
