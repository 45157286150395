import { getters as settingsGetters, state as settingsstate } from "./settings";
import { saveToLocalStorage } from "@/composables/helperUtils";
import {
  KCMPPProfile,
  KCMPPProfiles,
  KCMPPMemberFlag,
  KCMPPFeature,
} from "@/types/kcm-profile-api.types";
import { KcmContent, KcmProfileState, KcmCustomLocalMarket } from "@/types";
import { Commit } from "vuex";
import Vue from "vue";

const getDefaultState = (): KcmProfileState => {
  const lastSyncFromStorage = localStorage.getItem("profile.last_sync");
  let lastSync = 0;
  if (lastSyncFromStorage) {
    lastSync = parseInt(lastSyncFromStorage);
  }

  const flagsFromStorage = localStorage.getItem("profile.flags");
  let flags = [];
  if (flagsFromStorage) {
    flags = JSON.parse(flagsFromStorage);
  }

  const profileFromStorage = localStorage.getItem("profile.profile");
  if (profileFromStorage) {
    return {
      profile: JSON.parse(profileFromStorage),
      flags: flags,
      last_sync: lastSync,
    };
  }
  return {
    profile: {
      local: {},
      basic: {},
    },
    flags: flags,
    last_sync: lastSync,
  };
};

export const state: KcmProfileState = getDefaultState();

function memberPageRoot(): string {
  return (
    (process.env.VUE_APP_MEMBER_PAGE_ROOT as string) +
    settingsGetters.contentLanguageSetting(settingsstate) +
    "/"
  );
}

export const getters = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  hasFavoriteMetrics(_state: KcmProfileState, getters: any): boolean {
    const localData = getters.getProfileByFeature("local");
    if (!localData || !localData.favoriteMetrics) return false;
    return localData.favoriteMetrics.value.length > 0;
  },
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  getFavoriteMetrics(state: KcmProfileState, getters: any): string[] {
    const favoriteMetrics =
      getters.getProfileByFeature("local")?.favoriteMetrics?.value ?? "";
    if (!favoriteMetrics.length) {
      return [];
    }
    return favoriteMetrics.split(",");
  },
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  hasLocalMarkets(state: KcmProfileState, getters: any): boolean {
    const localData = getters.getProfileByFeature("local");

    if (!localData || !localData.markets) return false;
    return localData.markets.value.length > 0;
  },
  savedLocalMarkets(
    _state: KcmProfileState,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    getters: any
  ): KcmCustomLocalMarket[] {
    return getters.getProfileByFeature("local")?.markets?.value ?? [];
  },
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  getTrendlineColor(_state: KcmProfileState, getters: any): string {
    const color =
      getters.getProfileByFeature("local")?.trendlineColor?.value ?? "FF8300";
    return `#${color}`;
  },
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  getShowTrendline(_state: KcmProfileState, getters: any): boolean {
    const trendline =
      getters.getProfileByFeature("local")?.showTrendline?.value ?? "0";
    return trendline === "1" ? true : false;
  },
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  getAspectRatio(_state: KcmProfileState, getters: any): string {
    return getters.getProfileByFeature("local")?.aspectRatio?.value ?? "16:9";
  },
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  getDateRange(_state: KcmProfileState, getters: any): string[] {
    const dateRange: string =
      getters.getProfileByFeature("local")?.dateRange?.value;
    if (dateRange && dateRange.length) {
      return dateRange.split(",");
    }
    // return arr with two empty string values because this
    // will be the default anyways if member has no saved date ranges
    return ["", ""];
  },
  getRelativeTimeRange(state: KcmProfileState, getters: any): number {
    const relativeTimeRange =
      getters.getProfileByFeature("local")?.relativeTimeRange?.value;
    return relativeTimeRange !== undefined
      ? parseInt(relativeTimeRange, 10)
      : 12;
  },
  fullProfile(state: KcmProfileState): KCMPPProfiles {
    return state.profile;
  },
  profileAvatar(state: KcmProfileState): string {
    return state?.profile?.basic?.photo?.value
      ? (state.profile.basic.photo.value as string)
      : "";
  },
  profileLogo(state: KcmProfileState): string {
    return state?.profile?.basic?.logo?.value
      ? (state.profile.basic.logo.value as string)
      : "";
  },
  profileEmail(state: KcmProfileState): string {
    return state?.profile?.basic?.email?.value
      ? (state.profile.basic.email.value as string)
      : "";
  },
  profileWebsite(state: KcmProfileState): string {
    return state?.profile?.basic?.website?.value
      ? (state.profile.basic.website.value as string)
      : "";
  },
  profilePrimaryColor(state: KcmProfileState): string {
    return state?.profile?.basic?.primary_color?.value
      ? (state.profile.basic.primary_color.value as string)
      : process.env.VUE_APP_INDUSTRY_BRAND_COLOR ?? "00aeef";
  },
  profilePPCode(state: KcmProfileState): string {
    return (state.profile?.basic?.pp_code?.value as string) ?? "";
  },
  profileGuideLink:
    (state: KcmProfileState) =>
    (guide: string): string => {
      const guides = state.profile?.guides;
      if (guides) {
        return guides[guide].value as string;
      }
      return "";
    },
  profileSTMLink(state: KcmProfileState): string {
    return state?.profile?.basic?.pp_code?.value
      ? ((memberPageRoot() +
          "?a=" +
          state.profile.basic.pp_code.value) as string)
      : memberPageRoot();
  },
  profileSTMLinkExtended:
    (state: KcmProfileState) =>
    (link: string, language?: string): string => {
      const langAppend =
        language !== undefined && language !== "" ? language + "/" : "";
      const root =
        language !== undefined
          ? (process.env.VUE_APP_MEMBER_PAGE_ROOT as string) + langAppend
          : memberPageRoot();
      return state.profile?.basic?.pp_code?.value
        ? root + link + "?a=" + state.profile.basic.pp_code?.value
        : root + link;
    },
  profileSTMVideosLink(state: KcmProfileState): string {
    return getters.profileSTMLinkExtended(state)("videos/");
  },
  profileSTMVideoLink:
    (state: KcmProfileState) =>
    (content: KcmContent): string => {
      return getters.profileSTMLinkExtended(state)("videos/" + content.slug);
    },
  profileSTMBlogLink:
    (state: KcmProfileState) =>
    (content: KcmContent, lang: string): string => {
      const date = new Date(content.published_at);

      return getters.profileSTMLinkExtended(state)(
        date.getFullYear() +
          "/" +
          (date.getMonth() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "/" +
          date.getDate().toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }) +
          "/" +
          content.slug,
        lang
      );
    },
  profileFirstName(state: KcmProfileState): string {
    return state?.profile?.basic?.first_name?.value
      ? (state.profile.basic.first_name.value as string)
      : "me";
  },
  getProfileByFeature:
    (state: KcmProfileState) =>
    (feature: string): KCMPPProfile | string | number | undefined => {
      return state.profile[feature as KCMPPFeature]
        ? state.profile[feature as KCMPPFeature]
        : "";
    },
  lastSync(state: KcmProfileState): number {
    return state.last_sync;
  },
  profileAppendPPToSTM:
    (state: KcmProfileState) =>
    (contents: string): string => {
      let output = "";
      if (
        state.profile.basic &&
        state.profile.basic.pp_code &&
        state.profile.basic.pp_code.value
      ) {
        const regex = /(https?:\/\/www\.simplifyingthemarket\.com\/[^<>]*\/)/;
        const replace = new RegExp(regex, "g");
        output = contents.replace(
          replace,
          "$1?a=" + state.profile.basic.pp_code.value
        );
      } else {
        // no personalization
        return contents;
      }

      return output;
    },
  profileAppendPPAndSTMToRoot:
    (state: KcmProfileState) =>
    (contents: string, language: string): string => {
      const regex = /(href=")(\/)(.*?)(")/;
      const replace = new RegExp(regex, "g");

      return contents.replace(
        replace,
        "$1" +
          getters.profileSTMLinkExtended(state)(
            "$3",
            language === "en" ? language : ""
          ) +
          "$4"
      );
    },
  profileHasFlag:
    (state: KcmProfileState) =>
    (flagId: number): boolean => {
      const filterFlags = state.flags.filter((profileFlag) => {
        return profileFlag.flag.id === flagId;
      });
      return filterFlags.length > 0;
    },
  flagAddedSince:
    (state: KcmProfileState) =>
    (flagId: number, since: string): boolean => {
      const filterFlags = state.flags.filter((profileFlag) => {
        return profileFlag.flag.id === flagId;
      });
      if (filterFlags.length === 0) {
        return false;
      }
      const flagDate = new Date(filterFlags[0]?.last_added_at);
      const sinceDate = new Date(since);

      return flagDate > sinceDate;
    },
};

export const actions = {
  setCurrentProfile(
    { commit }: { commit: Commit },
    profile: KCMPPProfiles
  ): void {
    commit("SET_CURRENT_PROFILE", {});
    for (const feature in profile) {
      const feat = feature as KCMPPFeature;
      const featProfile = profile[feat];
      if (featProfile) {
        profile[feat] = setSyncToFields(featProfile);
      }
    }
    commit("SET_CURRENT_PROFILE", structuredClone(profile));
    commit("SET_LAST_SYNC");
  },
  setFlags({ commit }: { commit: Commit }, flags: KCMPPMemberFlag[]): void {
    commit("SET_CURRENT_FLAGS", flags);
    commit("SET_LAST_SYNC");
  },
  setCurrentProfileByFeature(
    { commit }: { commit: Commit },
    newValue: {
      feature: string;
      profile: KCMPPProfile;
    }
  ): void {
    newValue.profile = setSyncToFields(newValue.profile);
    commit("SET_CURRENT_PROFILE_BY_FEATURE", {
      feature: newValue.feature,
      profile: newValue.profile,
    });
    commit("SET_LAST_SYNC");
  },
  logout({ commit }: { commit: Commit }): void {
    commit("SET_CURRENT_PROFILE", {});
    commit("SET_LAST_SYNC");
  },
};

export const mutations = {
  SET_CURRENT_PROFILE(state: KcmProfileState, newValue: KCMPPProfiles): void {
    state.profile = newValue;
    saveToLocalStorage("profile.profile", state.profile);
  },

  SET_CURRENT_FLAGS(state: KcmProfileState, flags: KCMPPMemberFlag[]): void {
    state.flags = flags;
    saveToLocalStorage("profile.flags", state.flags);
  },

  SET_CURRENT_PROFILE_BY_FEATURE(
    state: KcmProfileState,
    newValue: {
      feature: KCMPPFeature;
      profile: KCMPPProfile;
    }
  ): void {
    if (!state.profile[newValue.feature]) {
      Vue.set(state.profile, newValue.feature, {});
    }
    state.profile[newValue.feature] = newValue.profile;
    saveToLocalStorage("profile.profile", state.profile); //needed to update localStorage
  },

  SET_LAST_SYNC(state: KcmProfileState): void {
    state.last_sync = parseInt(Date.now().toString());
    saveToLocalStorage("profile.last_sync", state.last_sync);
  },
};

function setSyncToFields(profile: KCMPPProfile): KCMPPProfile {
  Object.entries(profile).forEach(([key, field]) => {
    Object.entries(field).forEach(([key2, value2]) => {
      if (key2 === "SyncTo") {
        if (value2 === "") {
          if (field.value === "") {
            profile[key].SyncTo = "hide";
          } else {
            profile[key].SyncTo = "custom";
          }
        }
      }
    });
  });

  return profile;
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
