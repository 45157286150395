import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/content/:contentTypeSlug/:contentSlug/gallery",
    name: "Image Gallery",
    props: true,
    meta: { authRequired: true },
    component: () => import("@/router/views/personalizedContent/gallery.vue"),
  },
];

const redirects: RouteConfig[] = [
  { path: "/content/templates", redirect: "/resources" },
  { path: "/training-center", redirect: "/learning-center" },
];

/**
 * Routes that are specific to MyKCM / BB go here
 */
const industrySpecificRoutes: Record<string, RouteConfig[]> = {
  "1": [
    {
      path: "/learning-center",
      name: "Learning Center",
      meta: { authRequired: true },
      component: () =>
        import("@/router/views/trainingCenter/learning-center.vue"),
      props: { types: "best-practice,training-video,webinar-replay" },
    },
    {
      path: "/learning-center/best-practice/:slug",
      name: "Best Practice",
      props: true,
      meta: { authRequired: true },
      component: () =>
        import("@/router/views/trainingCenter/bestPracticeDetail.vue"),
    },
    {
      path: "/content/upcoming-live-training-events",
      redirect: "/learning-center",
    },
    {
      path: "/content/best-practice",
      redirect: { path: "/learning-center", query: { types: "best-practice" } },
    },
    {
      path: "/content/training-video",
      redirect: {
        path: "/learning-center",
        query: { types: "training-video" },
      },
    },
    {
      path: "/content/best-practice/:slug",
      redirect: { path: "/learning-center/best-practice/:slug" },
    },
    {
      path: "/content/training-video/:slug",
      redirect: { path: "/learning-center/training-video/:slug" },
    },
    {
      path: "/content/webinar-replay",
      redirect: {
        path: "/learning-center",
        query: { types: "webinar-replay" },
      },
    },
    {
      path: "/learning-center/webinar-replay/gettingstarted",
      name: "Getting Started with KCM - RSVP",
      meta: { authRequired: true },
      component: () =>
        import("@/router/views/trainingCenter/gettingStartedWebinar.vue"),
    },
    {
      path: "/webinars/gettingstarted",
      redirect: "/learning-center/webinar-replay/gettingstarted",
    },
    {
      path: "/content/webinar-replay/gettingstarted",
      redirect: "/learning-center/webinar-replay/gettingstarted",
    },
    {
      path: "/learning-center/webinar-replay/gettingstarted/replay",
      name: "Getting Started with KCM - Replay",
      meta: { authRequired: true },
      component: () =>
        import("@/router/views/trainingCenter/gettingStartedWebinarReplay.vue"),
    },
    {
      path: "/webinars/gettingstarted/replay",
      redirect: "/learning-center/webinar-replay/gettingstarted/replay",
    },
    {
      path: "/content/webinar-replay/gettingstarted/replay",
      redirect: "/learning-center/webinar-replay/gettingstarted/replay",
    },
    {
      path: "/learning-center/webinar-replay/brunch-n-learn",
      name: "KCM Brunch n' Learn",
      meta: { authRequired: true },
      component: () =>
        import("@/router/views/trainingCenter/brunchAndLearn.vue"),
    },
    {
      path: "/webinars/brunch-n-learn",
      redirect: "/learning-center/webinar-replay/brunch-n-learn",
    },
    {
      path: "/content/webinar-replay/brunch-n-learn",
      redirect: "/learning-center/webinar-replay/brunch-n-learn",
    },
    {
      path: "/learning-center/webinar-replay/:slug",
      name: "Webinar Replay",
      props: true,
      meta: { authRequired: true },
      component: () =>
        import("@/router/views/trainingCenter/webinarReplay.vue"),
    },
    {
      path: "/learning-center/training-video/:slug",
      name: "Training Video",
      props: true,
      meta: { authRequired: true },
      component: () =>
        import("@/router/views/trainingCenter/webinarReplay.vue"),
    },
    {
      path: "/content/webinar-replay/:slug",
      redirect: "/learning-center/webinar-replay/:slug",
    },
    {
      path: "/content/webinar/:slug",
      redirect: "/learning-center/webinar-replay/:slug",
    },
  ],
  "2": [
    {
      path: "/learning-center",
      name: "Learning Center",
      meta: { authRequired: true },
      component: () =>
        import("@/router/views/trainingCenter/learning-center.vue"),
    },
    {
      path: "/content/lunch-and-learns",
      name: "Lunch & Learns",
      meta: { authRequired: true },
      component: () => import("@/router/views/marketUpdates/content-page.vue"),
      props: {
        contentType: "lunch-and-learns",
        contentNameShort: "Lunch and Learn",
        contentNameLong: "Lunch and Learn",
        featureRequired: false,
        single: false,
      },
    },
    {
      path: "/content/strategy-calls",
      name: "Strategy Calls",
      meta: { authRequired: true },
      component: () =>
        import("@/router/views/marketUpdates/strategy-calls.vue"),
    },
    {
      path: "/content/exclusive-agent-webinars",
      name: "Exclusive Agent Webinars",
      meta: { authRequired: true },
      component: () =>
        import("@/router/views/marketUpdates/exclusiveAgentWebinars.vue"),
    },
    {
      path: "/content/templates/:slug",
      name: "Expert Guides Detail",
      props: true,
      meta: { authRequired: true },
      component: () => import("@/router/views/resources/resourceDetail.vue"),
    },
  ],
};

const learningCenterRoutes: Record<string, RouteConfig[]> = Object.keys(
  industrySpecificRoutes
).reduce((acc, industryId) => {
  acc[industryId] = [
    ...routes,
    ...industrySpecificRoutes[industryId],
    ...redirects,
  ];
  return acc;
}, {} as Record<string, RouteConfig[]>);

export default [...routes, ...redirects];
export { learningCenterRoutes };
