import store from "@/state/store";
import {
  KcmAutoposterJob,
  KcmAutoposterJobPostData,
  KcmAutoposterJobResponse,
  KcmAutoposterJobSchedule,
  KcmAutoposterJobsResponse,
  KcmAutoposterRun,
} from "@/types";
import axios, { AxiosRequestConfig } from "axios";
import { getTimezone } from "countries-and-timezones";
import { helpers } from "../helpers";

const baseUrl = process.env.VUE_APP_AUTOPOSTER_API_ROOT
  ? process.env.VUE_APP_AUTOPOSTER_API_ROOT.replace(/\/$/, "")
  : "";

const HEADERS = (): AxiosRequestConfig => {
  const userToken = store.getters["auth/authToken"];
  if (userToken !== undefined && userToken !== null) {
    return {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    };
  }
  return {};
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
function get(resource: string): any {
  return axios.get(baseUrl + resource, HEADERS());
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
function post(resource: string, data: unknown): any {
  return axios.post(baseUrl + resource, data, HEADERS());
}

// function put(resource: string, data: unknown): any {
//   return axios.put(baseUrl + resource, data, HEADERS());
// }

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
function deleteAxios(resource: string): any {
  return axios.delete(baseUrl + resource, HEADERS());
}

export const autopostService = {
  hasJob,
  hasJobWithSocial,
  getJobsForUser,
  createJobForUser,
  updateJobForUser,
  pauseJobForUser,
  resumeJobForUser,
  deleteJobForUser,
};

async function hasJob(): Promise<boolean> {
  return await getJobsForUser(false, 0, false).then((result) => {
    if (result.error) {
      return false;
    }
    return result.data.jobs?.length > 0;
  });
}

async function hasJobWithSocial(socialId: number): Promise<boolean> {
  return await getJobsForUser(false, 0).then((result) => {
    if (result.error) {
      return false;
    }
    return (
      result.data.jobs?.filter((job) => {
        return (
          job.job_networks.filter((job_network) => {
            return job_network.network.id === socialId;
          }).length > 0
        );
      }).length > 0
    );
  });
}

async function getJobsForUser(
  breakCache = false,
  page = 0,
  getMore = true
): Promise<KcmAutoposterJobsResponse> {
  if (store.getters["autoposter/autoposterJobs"] && !breakCache) {
    return store.getters["autoposter/autoposterJobs"];
  }
  const endpoint =
    "/industries/" +
    process.env.VUE_APP_INDUSTRY_ID +
    "/jobs/?user_id=" +
    store.getters["auth/authUserId"] +
    "&page=" +
    page;

  const initialPage = page;

  return get(endpoint)
    .then(async (result: KcmAutoposterJobsResponse) => {
      if (result.data?.jobs) {
        if (result.data.has_more === true && getMore) {
          const newResult = await getJobsForUser(breakCache, page + 1);
          if (newResult.data?.jobs) {
            result.data.jobs = result.data.jobs.concat(newResult.data.jobs);
          }
        }
        if (initialPage === 0) {
          store.dispatch("autoposter/setAutoposterJobs", result);
          result.data.jobs.forEach((job, index) => {
            result.data.jobs[index] = adjustSchedulesForTimezoneGet(job);
          });
        }

        return result;
      }
      return { error: "No jobs" };
    })
    .catch(() => {
      return { error: "Failed to get jobs for member" };
    });
}

function adjustSchedulesForTimezoneGet(
  jobData: KcmAutoposterJob
): KcmAutoposterJob {
  const getData = JSON.parse(JSON.stringify(jobData));

  const timezoneStringCheck = Object.values(helpers.timezoneTypes).filter(
    (tz) => {
      return tz.autoposter === jobData.timezone.id;
    }
  );
  const timezoneString =
    timezoneStringCheck.length > 0
      ? timezoneStringCheck[0].js
      : "America/New_York";

  // adjust from UTC to timezone on get
  getData.job_schedules.forEach(
    (slot: KcmAutoposterJobSchedule, index: number) => {
      const hour = slot.hour;
      const now = new Date();
      const date = new Date(
        now.getFullYear() +
          "-" +
          ("0" + (now.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + now.getDate()).slice(-2) +
          "T" +
          ("0" + hour).slice(-2) +
          ":00:00.000Z"
      );

      const dateStr = date.toLocaleTimeString("en-US", {
        timeZone: timezoneString,
        hour: "numeric",
        hour12: false,
      });

      const finalHour = dateStr === "24" ? "0" : dateStr;
      getData.job_schedules[index].hour = parseInt(finalHour);
    }
  );
  getData.run.sort((a: KcmAutoposterRun, b: KcmAutoposterRun) => {
    if (a.updated_at > b.updated_at) {
      return -1;
    } else if (a.updated_at < b.updated_at) {
      return 1;
    }

    if (a.completed_at && b.completed_at) {
      if (a.completed_at > b.completed_at) {
        return -1;
      } else if (a.completed_at < b.completed_at) {
        return 1;
      }
    }
    if (a.completed_at) {
      return 1;
    } else {
      return -1;
    }
  });
  return getData;
}

function adjustPostData(
  jobData: KcmAutoposterJobPostData
): KcmAutoposterJobPostData {
  const sendData = JSON.parse(JSON.stringify(jobData));

  const timezoneStringCheck = Object.values(helpers.timezoneTypes).filter(
    (tz) => {
      return tz.autoposter === jobData.timezone?.id ?? 16;
    }
  );

  const timezoneString =
    timezoneStringCheck.length > 0
      ? timezoneStringCheck[0].js
      : "America/New_York";

  // adjust to UTC before post
  const tz = getTimezone(timezoneString);
  const isDst = helpers.isDST();

  let offset = "";
  if (isDst) {
    offset = tz?.dstOffsetStr ?? "";
  } else {
    offset = tz?.utcOffsetStr ?? "";
  }

  sendData.job_schedules.forEach(
    (slot: KcmAutoposterJobSchedule, index: string) => {
      const dbm = new Date(
        "1990-02-07T" + ("0" + slot.hour).slice(-2) + ":00:00.000" + offset
      );

      const dateStr = dbm.toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour: "numeric",
        hour12: false,
      });
      let hour = parseInt(dateStr);
      hour = hour === 24 ? 0 : hour;
      sendData.job_schedules[index].hour = hour;
    }
  );

  sendData.language_id = sendData.language?.id ?? 1;
  sendData.timezone_id = sendData.timezone?.id ?? 15;
  delete sendData.timezone;
  delete sendData.language;
  return sendData;
}

async function createJobForUser(
  newJob: KcmAutoposterJobPostData
): Promise<KcmAutoposterJobResponse> {
  const endpoint = "/industries/" + process.env.VUE_APP_INDUSTRY_ID + "/jobs/";

  const postData = adjustPostData(newJob);

  return post(endpoint, postData)
    .then(async (result: KcmAutoposterJobResponse) => {
      if (result.data?.job) {
        return result;
      }
      return { error: "Failed to update job for member" };
    })
    .catch(() => {
      return { error: "Failed to update job for member" };
    });
}

async function updateJobForUser(
  jobId: number,
  updates: KcmAutoposterJobPostData
): Promise<KcmAutoposterJobResponse> {
  const endpoint =
    "/industries/" + process.env.VUE_APP_INDUSTRY_ID + "/jobs/" + jobId;

  const postData = adjustPostData(updates);

  return post(endpoint, postData)
    .then(async (result: KcmAutoposterJobResponse) => {
      if (result.data?.job) {
        return result;
      }
      return { error: "Failed to update job for member" };
    })
    .catch(() => {
      return { error: "Failed to update job for member" };
    });
}

async function pauseJobForUser(
  jobId: number
): Promise<KcmAutoposterJobResponse> {
  const endpoint =
    "/industries/" + process.env.VUE_APP_INDUSTRY_ID + "/jobs/" + jobId;

  return post(endpoint, { status: false })
    .then(async (result: KcmAutoposterJobResponse) => {
      if (result.data?.job) {
        return result;
      }
      return { error: "Failed to pause job for member" };
    })
    .catch(() => {
      return { error: "Failed to pause job for member" };
    });
}

async function resumeJobForUser(
  jobId: number
): Promise<KcmAutoposterJobResponse> {
  const endpoint =
    "/industries/" + process.env.VUE_APP_INDUSTRY_ID + "/jobs/" + jobId;

  return post(endpoint, { status: true })
    .then(async (result: KcmAutoposterJobResponse) => {
      if (result.data?.job) {
        return result;
      }
      return { error: "Failed to resume job for member" };
    })
    .catch(() => {
      return { error: "Failed to resume job for member" };
    });
}

async function deleteJobForUser(
  jobId: number
): Promise<KcmAutoposterJobResponse> {
  const endpoint =
    "/industries/" + process.env.VUE_APP_INDUSTRY_ID + "/jobs/" + jobId;

  return deleteAxios(endpoint)
    .then(async (result: KcmAutoposterJobResponse) => {
      if (result.data?.job) {
        return result;
      }
      return { error: "Failed to delete job for member" };
    })
    .catch(() => {
      return { error: "Failed to delete job for member" };
    });
}
