function serviceId(): string {
  return "guest";
}

export const guestService = {
  serviceId,
  status,
  redirects,
};

function status(): string {
  return "active";
}

function redirects(): { [x: string]: string } {
  return {
    dunning: "/dashboard",
    upgrade: "/dashboard",
  };
}
