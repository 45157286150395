import UAParser from "ua-parser-js";

let userAgent: UAParser.UAParserInstance;

export interface DeviceData {
  // device_vendor: string | undefined;
  device_type: string;
  browser_name: string | undefined;
  // browser_version: string | undefined;
  os_name: string | undefined;
  // os_version: string | undefined;
}

const setupUserAgentParser = (): UAParser.UAParserInstance => {
  return new UAParser();
};

// there is no specific 'desktop' string
// to be parsed from the user-agent, so
// we will infer the desktop type from
// what it is not.
const getDeviceType = (): string => {
  const type = userAgent.getDevice().type;

  const isDesktop =
    type === undefined ||
    ![
      "wearable",
      "mobile",
      "console",
      "tablet",
      "smarttv",
      "embedded",
    ].includes(type);

  return isDesktop ? "desktop" : type;
};

export const getUAData = (): DeviceData => {
  if (!userAgent) {
    userAgent = setupUserAgentParser();
  }

  return {
    // device_vendor: userAgent.getDevice().vendor,
    device_type: getDeviceType(),
    browser_name: userAgent.getBrowser().name,
    // browser_version: userAgent.getBrowser().version,
    os_name: userAgent.getOS().name,
    // os_version: userAgent.getOS().version,
  };
};
