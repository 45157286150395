import { ApplePaySubscriptionData } from "@/types";
import store from "../../state/store";

function serviceId(): string {
  return "apple";
}

export const applePayService = {
  serviceId,
  status,
  subscription_data,
  redirects,
};

function status(): string {
  return store.getters["auth/getServiceByField"]("apple-pay", "status");
}

function subscription_data(): ApplePaySubscriptionData {
  return {
    original_transaction_id: store.getters["auth/getServiceByField"](
      "apple-pay",
      "original_transaction_id"
    ),
    status: store.getters["auth/getServiceByField"]("apple-pay", "status"),
  };
}

function redirects(): { [x: string]: string } {
  return {
    dunning: "/subscription",
    upgrade: "/subscription",
  };
}
