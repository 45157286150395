import { KcmContent } from "./../types/index";
/* eslint-disable  @typescript-eslint/no-explicit-any */
import i18n from "@/i18n";
import TrackingService from "@/services/trackingservice/tracking.service";
import { SelectOptions } from "@/types";
import JSZip from "jszip";
import { KcmContentEvent } from "@/services/trackingservice/tracking.service";
import { helpers } from "@/services/helpers";

export const insensitiveAssign = (
  target: { [x: string | number]: any },
  source: { [x: string | number]: any }
): any => {
  Object.keys(source).forEach((key) => {
    const s_val = source[key];
    const t_val = target[key];
    target[key] =
      t_val && s_val && typeof t_val === "object" && typeof s_val === "object"
        ? insensitiveAssign(t_val, s_val)
        : s_val;
  });

  return target;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepCopy = (obj: any): any => {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    const copy = [];
    for (let i = 0; i < obj.length; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const copy = {} as any;
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      copy[key] = deepCopy(obj[key]);
    }
  }

  return copy;
};

export function saveToLocalStorage(key: string, state: any): void {
  localStorage.setItem(key, JSON.stringify(state));
}

export function yearOptions(
  diff: number,
  includeAllOption = true
): SelectOptions[] {
  const currentYear = new Date().getFullYear();
  const minYear = currentYear - diff;
  const yearOptions: SelectOptions[] = [];
  if (includeAllOption) {
    yearOptions.push({
      text: i18n.t("components.content-feed.archives.all") as string,
      value: "",
    });
  }
  for (let i = currentYear; i >= minYear; i--) {
    yearOptions.push({ text: i.toString(), value: i.toString() });
  }
  return yearOptions;
}

export function getTranslation(string: string): string {
  return i18n.t(string) as string;
}

export function downloadFile(
  download: { name: string; url: string },
  content: KcmContent,
  trackingOptions?: KcmContentEvent
): void {
  TrackingService.trackEvent(
    "downloadedContent",
    trackingOptions ?? {
      contentType: content.content_type,
      content: content,
      fileName: download.name,
    }
  );

  const link = document.createElement("a");
  link.download = download.name;
  link.href = download.url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function monthOptions(includeAllOption = true): SelectOptions[] {
  const monthOptions: SelectOptions[] = [];
  if (includeAllOption) {
    monthOptions.push({ text: "All Months", value: "" });
  }
  for (let i = 0; i <= 11; i++) {
    const monthName = new Date(2022, i, 1).toLocaleDateString(i18n.locale, {
      month: "long",
    });
    monthOptions.push({ text: monthName, value: (i + 1).toString() });
  }
  return monthOptions;
}

export const timezoneOptions: SelectOptions[] = [
  {
    text: "Eastern",
    value: "America/New_York",
  },
  {
    text: "Central",
    value: "America/Chicago",
  },
  {
    text: "Mountain",
    value: "America/Denver",
  },
  {
    text: "Mountain (no DST)",
    value: "America/Phoenix",
  },
  {
    text: "Pacific",
    value: "America/Los_Angeles",
  },
  {
    text: "Alaska",
    value: "America/Anchorage",
  },
  {
    text: "Hawaii-Aleutian",
    value: "America/Adak",
  },
  {
    text: "Hawaii-Aleutian (no DST)",
    value: "Pacific/Honolulu",
  },
];

export function capitalizeString(base: string): string {
  return base[0].toUpperCase() + base.slice(1).toLowerCase();
}

export function formatUrlWithProtocol(url: string): string {
  if (url.includes("http")) return url;
  return `${window.location.protocol}//${url}`;
}

export async function copyHtmlToClipboard(
  data: string,
  event?: KcmContentEvent
): Promise<void> {
  /** if an event was passed into this request to copy html then track it */
  if (event) {
    TrackingService.trackEvent("copiedContent", event);
  }

  try {
    // legacy code from mykcm that works in firefox
    const container = document.createElement("div");
    container.innerHTML = data;
    container.style.position = "fixed";
    container.style.pointerEvents = "none";
    container.style.backgroundColor = "white";
    document.body.appendChild(container);

    window.getSelection()?.removeAllRanges();

    const range = document.createRange();
    range.selectNode(container);
    window.getSelection()?.addRange(range);
    document.execCommand("copy");
    document.body.removeChild(container);
  } catch (error) {
    helpers.customSentryError("Copy Error", "copy to clipboard", {
      error: error,
    });
  }

  // This is supposed to replace the deprecated execCommand but doesnt work on mobile so
  // yeah we're just going to use execCommand until it breaks
  // const blobInput = new Blob([data], { type: "text/html" });
  // const clipboardItemInput = new ClipboardItem({ "text/html": blobInput });
  // const resp = await navigator.clipboard.write([clipboardItemInput]);
}

export function relativeDateFormat(publishDate: string): string {
  const daysInMilli = 86400000;
  const currentDate = new Date();
  const convertedPubDate = new Date(publishDate);
  const daysDiff =
    (currentDate.getTime() - convertedPubDate.getTime()) / daysInMilli;
  //use hours string
  if (daysDiff < 1) {
    const hours = Math.floor(daysDiff * 24);
    //use minutes
    if (!hours) {
      const minutes = Math.floor(
        (currentDate.getTime() - convertedPubDate.getTime()) / 1000 / 60
      );
      if (minutes < 1) {
        return "just now";
      }
      return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    } else {
      return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    }
    //use days
  } else if (daysDiff >= 1 && daysDiff <= 14) {
    return `${Math.floor(daysDiff)} day${daysDiff === 1 ? "" : "s"} ago`;
    //use week
  } else if (daysDiff >= 14 && daysDiff <= 62) {
    return `${Math.floor(daysDiff / 7)} weeks ago`;
    //use month
  } else if (daysDiff >= 62 && daysDiff < 365) {
    const monthDiff =
      currentDate.getMonth() -
      convertedPubDate.getMonth() +
      12 * (currentDate.getFullYear() - convertedPubDate.getFullYear());
    return `${monthDiff} months ago`;
    //use year
  } else {
    const yearDiff = currentDate.getFullYear() - convertedPubDate.getFullYear();
    return `${yearDiff} year${yearDiff === 1 ? "" : "s"} ago`;
  }
}

export class KCMHelperUtils {
  groupByProperty(
    assetArray: any[],
    property: string
  ): { [key in string]: any[] } {
    return assetArray.reduce(function (group, originalItem) {
      group[originalItem[property]] = group[originalItem[property]] || [];
      group[originalItem[property]].push(originalItem);
      return group;
    }, Object.create(null));
  }

  customGroupByProperty(
    assetArray: any[],
    groupBy: (obj: any) => any,
    date?: boolean
  ): { [key in string]: any[] } {
    const tempArray = assetArray.reduce(function (group, originalItem) {
      const groupVal = groupBy(originalItem);

      group[groupVal] = group[groupVal] || [];
      group[groupVal].push(originalItem);
      return group;
    }, Object.create(null));

    // sort by keys in descending order
    return Object.keys(tempArray)
      .sort(
        date
          ? (a: any, b: any): any =>
              new Date(a).getTime() - new Date(b).getTime()
          : undefined
      )
      .reverse()
      .reduce((obj: Record<string, any>, key: keyof typeof obj) => {
        obj[key] = tempArray[key];
        return obj;
      }, {});
  }

  getBase64ImageFromUrl(
    url: string,
    format = "png"
  ): Promise<{ url: string; data: string }> {
    return new Promise((resolve) => {
      const tempImg = new Image();
      tempImg.crossOrigin = "Anonymous";
      tempImg.src = url + "?use=canvas";
      if (url.includes("?")) {
        tempImg.src = url + "&use=canvas";
      }
      let base64 = "";

      tempImg.onload = (): void => {
        const canvas = document.createElement("canvas");
        canvas.width = tempImg.width;
        canvas.height = tempImg.height;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(tempImg, 0, 0);

        const dataURL =
          format === "png"
            ? canvas.toDataURL("image/png")
            : canvas.toDataURL("image/jpeg", 0.8);
        base64 = dataURL.replace(/^data:image\/?[A-z]*;base64,/, "");
        return resolve({ url: url, data: base64 });
      };
    });
  }

  loadImage(src: string, anonymous: boolean): Promise<HTMLImageElement> {
    const promise: Promise<HTMLImageElement> = new Promise(
      (resolve, reject) => {
        const img = new Image();
        if (anonymous) img.crossOrigin = "Anonymous";
        img.src = src;
        img.onload = (): void => {
          resolve(img);
        };
        img.onerror = (e): void => {
          reject(e);
        };
      }
    );
    return promise;
  }

  async backgroundImageForChart(
    x: number,
    y: number,
    w: number,
    h: number,
    b64Image: string,
    appendDataUrl = true
  ): Promise<string> {
    const canvas = document.createElement("canvas");

    canvas.width = 1080;
    canvas.height = 1920;
    const ctx = canvas.getContext("2d");

    try {
      const bgImg = await this.loadImage(
        "https://files.mykcm.com/site/scriptbuilder/rt-bg.png",
        true
      );

      let dataUrl = "";
      if (appendDataUrl) {
        dataUrl = "data:image/png;base64,";
      }

      const chart = await this.loadImage(dataUrl + b64Image, true);

      ctx?.drawImage(bgImg, 0, 0);
      ctx?.drawImage(chart, x, y, w, h);

      return canvas.toDataURL();
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Failed to generate background image for chart.");
      }
    }
  }

  async addFileToDir(
    dir: JSZip | null,
    file: string,
    data: string
  ): Promise<void> {
    const fileSplit = file.split("/");
    dir?.file(fileSplit[fileSplit.length - 1], data, { base64: true });
  }

  downloadAllImages(urls: string[]): Promise<{ url: string; data: string }[]> {
    return Promise.all(
      urls.map((url) => {
        return this.getBase64ImageFromUrl(url);
      })
    );
  }

  async createZip(
    data: { [x: string]: string[] | string } | string[],
    name: string
  ): Promise<boolean> {
    const zip = new JSZip();

    if (Array.isArray(data)) {
      // no directories, just flat zip containing files
      await this.downloadAllImages(data).then((files) => {
        files.map((file: { url: string; data: string }) => {
          this.addFileToDir(zip, file.url, file.data);
        });
      });
    } else {
      // can handle one layer of directories
      for (const directory of Object.entries(data)) {
        const dir = zip.folder(directory[0]);
        if (dir) {
          await this.downloadAllImages(directory[1] as string[])
            .then((files) => {
              files.map((file: { url: string; data: string }) => {
                this.addFileToDir(dir, file.url, file.data);
              });
            })
            .catch((err) => {
              helpers.customSentryError(
                "Failed to create zip file: " + name,
                "",
                {
                  error: err,
                }
              );
            });
        }
      }
    }

    const resp = zip
      .generateAsync({ type: "blob" })
      .then((content) => {
        const tempLink = document.createElement("a");
        tempLink.setAttribute("href", URL.createObjectURL(content));
        tempLink.setAttribute("download", name + ".zip");
        tempLink.click();
        return true;
      })
      .catch(() => {
        return false;
      });

    return resp;
  }

  isMobile(): boolean {
    // User agent string method
    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    // Screen resolution method
    if (!isMobile) {
      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      isMobile = screenWidth < 768 || screenHeight < 768;
    }
    // Touch events method
    if (!isMobile) {
      isMobile = "ontouchstart" in window || navigator.maxTouchPoints > 0;
    }
    // CSS media queries method
    if (!isMobile) {
      const bodyElement = document.getElementsByTagName("body")[0];
      isMobile =
        window
          .getComputedStyle(bodyElement)
          .getPropertyValue("content")
          .indexOf("mobile") !== -1;
    }
    return isMobile;
  }

  USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
}

/**
 *
 * @param base64Str - takes in a base64 encoded image, removes data:image && base64 text
 * @returns a base64 Buffer for use with uploading to aws
 */
export function createBuffer(base64Str: string): Buffer {
  return Buffer.from(
    base64Str.replace(/^data:image\/\w+;base64,/, ""),
    "base64"
  );
}

/**
 *
 * @param monthsBack a natural number representing how many months in the past from the current
 *                   month the date should be set - if current month is June and the number 2
 *                   is passed, the returned <Date> should be set to the beginning of April
 * @returns a <Date> string representing the month in the past
 */
export function setDateInPast(monthsBack: number): Date {
  const date = new Date();
  date.setDate(1);

  date.setMonth(date.getMonth() - monthsBack);
  return date;
}

/**
 *
 * @param b64Data base64 string
 * @param contentType the mimetype
 * @param sliceSize
 *        the byte chunk size to process at once, if done in smaller chunks as opposed to the entire
 *        byte array, there is a performance boost
 * @returns <Blob> for uploading to s3
 */
export function b64toBlob(
  b64Data: string,
  contentType = "",
  sliceSize = 512
): Blob {
  b64Data = b64Data.includes(",") ? b64Data.split(",")[1] : b64Data;
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function downloadFileBlob(data: Blob, filename: string): void {
  const href = URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export enum PublishSinceValues {
  Week = "week",
  Month = "month",
  HalfYear = "halfYear",
  Year = "year",
  Custom = "custom",
  All = "all",
  Empty = "",
}

export function getDateFilter(label: PublishSinceValues): string {
  if (label === PublishSinceValues.Empty) return "";
  const date = new Date();
  let useAll = false;

  switch (label) {
    case "all":
      useAll = true;
      break;
    case "week":
      date.setDate(date.getDate() - 7);
      break;
    case "month":
      date.setMonth(date.getMonth() - 1);
      break;
    case "halfYear":
      date.setMonth(date.getMonth() - 6);
      break;
    case "year":
      date.setMonth(date.getMonth() - 12);
      break;

    default:
      break;
  }

  if (useAll) {
    return "";
  }
  return date.toISOString();
}
