import * as Sentry from "@sentry/browser";
const featureTypes = Object.freeze({
  Blog: "personalized-blog",
  Video: "video",
  SocialGraphics: "social-graphics",
});
const contentTypes = Object.freeze({ Blogs: 1, Videos: 2, SocialGraphics: 3 });
const instagramLayoutTypes = Object.freeze({
  Layout1: 1,
  Layout2: 2,
  Layout3: 3,
  Layout4: 4,
  Layout5: 5,
});
const languageTypes = Object.freeze({ English: 1, Spanish: 2 });
const skipCategoryTypes = Object.freeze({ Holiday: 82 });
const networkTypes = Object.freeze({
  Facebook: 1,
  Twitter: 2,
  Linkedin: 3,
  Instagram: 4,
});
const timezoneTypes = Object.freeze({
  America_ADAK_HDT: {
    autoposter: 1,
    js: "America/Adak",
    copy: "Hawaii-Aleutian",
    abbreviation: "HAST",
  },
  America_Anchorage_AKDT: {
    autoposter: 2,
    js: "America/Anchorage",
    copy: "Alaskan",
    abbreviation: "AKST",
  },
  America_LosAngeles_PDT: {
    autoposter: 15,
    js: "America/Los_Angeles",
    copy: "Pacific",
    abbreviation: "PST",
  },
  America_Denver_MDT: {
    autoposter: 9,
    js: "America/Denver",
    copy: "Mountain",
    abbreviation: "MST",
  },
  America_Chicago_CDT: {
    autoposter: 8,
    js: "America/Chicago",
    copy: "Central",
    abbreviation: "CST",
  },
  America_NewYork_EDT: {
    autoposter: 16,
    js: "America/New_York",
    copy: "Eastern",
    abbreviation: "EST",
  },
  America_Indianapolis_EDT: {
    autoposter: 13,
    js: "America/Indianapolis",
    copy: "EST Indiana",
    abbreviation: "EST (no DST)",
  },
  America_Phoenix_MST: {
    autoposter: 18,
    js: "America/Phoenix",
    copy: "Mountain Standard",
    abbreviation: "MST (no DST)",
  },
  Pacific_Honolulu_HST: {
    autoposter: 36,
    js: "Pacific/Honolulu",
    copy: "Honolulu",
    abbreviation: "HAST (no DST)",
  },
});

function isDST(): boolean {
  const d = new Date();
  const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== d.getTimezoneOffset();
}

export const helpers = {
  featureTypes,
  contentTypes,
  instagramLayoutTypes,
  skipCategoryTypes,
  languageTypes,
  networkTypes,
  timezoneTypes,
  parseJwt,
  isDST,
  customSentryError,
  scrollToBottom,
  stripCharacterIfExists,
};

function parseJwt(token: string): string {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return "";
  }
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
function customSentryError(name: string, message: string, extra: any): void {
  const sentryError = new Error(message);
  sentryError.name = name;
  const sentryExtras = { extra: extra };
  for (const key in sentryExtras.extra) {
    if (typeof sentryExtras.extra[key] === "object") {
      sentryExtras.extra[key] = JSON.stringify(sentryExtras.extra[key]);
    }
  }
  Sentry.captureException(sentryError, sentryExtras);
}

/**
 *
 * @param el <Element>
 * @param useTimeout <boolea>
 * @param delay <number>
 *
 * Takes an Element and scrolls to the bottom of its scrollable region
 * with a smooth animation. Optionally includes a timeout if the content
 * being displayed is dynamic or requires loading(ie. images) before it
 * renders.
 *
 * Works in major vendors except ie and opera mini: 97.1% coverage.
 */
function scrollToBottom(el: Element, useTimeout = false, delay = 500): void {
  const scrollFunc = function (): void {
    el.scrollTo({
      top: el.scrollHeight,
      behavior: "smooth",
    });
  };

  if (useTimeout) {
    setTimeout(scrollFunc, delay);
  } else {
    scrollFunc();
  }
}

function stripCharacterIfExists(
  input: string,
  charToStrip: string
): { result: string; wasStripped: boolean } {
  if (input.includes(charToStrip)) {
    const result = input.split(charToStrip).join("");
    return {
      result,
      wasStripped: true,
    };
  }
  return {
    result: input,
    wasStripped: false,
  };
}
