import { RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/profile/basic",
    name: "Personalization Profile",
    meta: { authRequired: true },
    component: () => import("@/router/views/profile/pp/basic.vue"),
  },
  {
    path: "/profile/blog",
    name: "Blog Settings",
    meta: { authRequired: true },
    component: () => import("@/router/views/profile/pp/blog.vue"),
  },
  {
    path: "/profile/graphics",
    name: "Graphics Settings",
    meta: { authRequired: true },
    component: () => import("@/router/views/profile/pp/graphics.vue"),
  },
  {
    path: "/profile/guides",
    name: "Guides Settings",
    meta: { authRequired: true },
    component: () => import("@/router/views/profile/pp/guides.vue"),
  },
  {
    path: "/profile/email-builder",
    name: "Email Builder Settings",
    meta: { authRequired: true, featureRequired: "email-builder" },
    component: () => import("@/router/views/profile/pp/email.vue"),
  },
];

const redirects: RouteConfig[] = [
  { path: "/profile", redirect: "/profile/basic" },
  { path: "/profile/email", redirect: "/profile/email-builder" },
  { path: "/profile/newsletter", redirect: "/profile/email-builder" },
];

/**
 * Routes that are specific to MyKCM / BB go here
 */
const industrySpecificRoutes: Record<string, RouteConfig[]> = {
  "1": [
    {
      path: "/profile/videos",
      name: "Video Settings",
      meta: { authRequired: true, featureRequired: "video" },
      component: () => import("@/router/views/profile/pp/videos.vue"),
    },
  ],
  "2": [],
};

const profileRoutes: Record<string, RouteConfig[]> = Object.keys(
  industrySpecificRoutes
).reduce((acc, industryId) => {
  acc[industryId] = [
    ...routes,
    ...industrySpecificRoutes[industryId],
    ...redirects,
  ];
  return acc;
}, {} as Record<string, RouteConfig[]>);

export default [...routes, ...redirects];
export { profileRoutes };
