import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { helpers } from "@/services/helpers";
import store from "@/state/store";
import {
  ZendeskSsoData,
  LocalBlockAIPayload,
  CtaListsInterface,
} from "@/types";

const HOST = process.env.VUE_APP_SERVICES_API_ROOT
  ? process.env.VUE_APP_SERVICES_API_ROOT.replace(/\/$/, "")
  : "";

const HEADERS = (): AxiosRequestConfig => {
  const userToken = store.getters["auth/authToken"];
  if (userToken !== undefined && userToken !== null) {
    return {
      headers: {
        authorization: `Bearer ${userToken}`,
      },
    };
  }

  return {};
};

const industryId = process.env.VUE_APP_INDUSTRY_ID ?? "1";

export const thirdPartyService = {
  get,
  post,
  put,
  deleteAxios,
  sendTransactionalEmail,
  createSupportLogin,
  HEADERS,
  addToHubspotWorkflow,
  addToHubspotList,
  getHubspotToken,
  updateHubspotContact,
  getMembersHubspotLists,
  getLocalChartBlockTextAxios,
  getBynderImages,
};

export const Emails = {
  "1": {
    CancelRequestToPause: 95353748540,
  },
  "2": {
    CancelRequestToPause: 0,
  },
};

export const CtaLists = {
  GettingStartedWebinar: 1570,
  BrunchAndLearn: 1571,
  DailyBlog: { "1": 809, "2": 3259 },
  MemberWebinar: 1572,
  OpenHouseOptIn: 1919,
  OpenHouseAccess: 1937,
  LocalOptIn: 2304,
} as CtaListsInterface;

function get(
  resource: string,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  headers?: Record<string, any>
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> {
  return axios.get(HOST + resource, { ...HEADERS(), ...headers });
}

function post(
  resource: string,
  data: unknown
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> {
  return axios.post(HOST + resource, data, HEADERS());
}

function put(
  resource: string,
  data: unknown
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> {
  return axios.put(HOST + resource, data, HEADERS());
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
function deleteAxios(resource: string): Promise<AxiosResponse<any, any>> {
  return axios.delete(HOST + resource, HEADERS());
}

function addToHubspotWorkflow(
  emailAddress: string,
  workflowId: number
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> {
  const path =
    "/hubspot/v1/industries/" +
    industryId +
    "/contact/" +
    emailAddress +
    "/workflows/" +
    workflowId;

  return post(path, null).finally(() => {
    /**
     * Hubspot has varying delays between entering the workflow and
     * getting the list added to the account, we'll use a 1 minute delay
     * before fetching just to try and catch all list changes and we'll
     * temporarily force the list into the store.
     */
    //disabling this function for now because Hubspot timing is too unreliable
    //when adding contacts to a workflow.
    // setTimeout(() => {
    //   getMembersHubspotLists(emailAddress);
    // }, 60000);
  });
}

function addToHubspotList(
  emailAddress: string,
  listId: number
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> {
  const path =
    "/hubspot/v1/industries/" +
    industryId +
    "/contact/" +
    emailAddress +
    "/lists/" +
    listId;

  return post(path, null);
}

function getHubspotToken(
  emailAddress: string
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> {
  const path =
    "/hubspot/v1/industries/" +
    industryId +
    "/contact/" +
    emailAddress +
    "/identity-token";

  return get(path);
}

export interface HubspotFields {
  [x: string]: string | boolean;
}

function updateHubspotContact(
  emailAddress: string,
  hubspotFields: HubspotFields
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> {
  const path =
    "/hubspot/v1/industries/" + industryId + "/contact/" + emailAddress;

  return post(path, hubspotFields);
}

async function getMembersHubspotLists(emailAddress: string): Promise<void> {
  const path =
    "/hubspot/v1/industries/" +
    industryId +
    "/contact/" +
    emailAddress +
    "/lists/";

  store.dispatch("crm/setLoading", true);

  await get(path)
    .then((response) => {
      const lists = response.data.lists.map(
        // eslint-disable-next-line
        (l: Record<string, any>) => l["static-list-id"]
      );

      store.dispatch("crm/setContactLists", lists);
    })
    .catch((err) => {
      console.log("Failed to load hubspot lists: ", err);
    })
    .finally(() => {
      store.dispatch("crm/setLoading", false);
    });
}

function sendTransactionalEmail(
  emailId: number,
  emailAddress: string,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data?: Record<string, any>
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> {
  return post("/hubspot/v1/industries/" + industryId + "/email/" + emailId, {
    user_email: emailAddress,
    custom_data: data,
  });
}

export interface SsoResponseData {
  url: string;
  jwt: string;
}

async function createSupportLogin(
  ssoData: ZendeskSsoData
): Promise<SsoResponseData> {
  const response = await post("/zendesk/v1/sso/", ssoData).catch((err) => {
    helpers.customSentryError(
      "Support Login Error",
      "Error logging into Zendesk",
      {
        err: err,
      }
    );
    return { data: { url: "", jwt: "" } };
  });
  return response.data;
}

/**
 *
 * @param data <LocalBlockAIPayload>
 * @returns <string>
 *    builds out all the needed query params from the LocalBlockAIPayload
 *    when retrieving AI block text
 */
function serialize(data: LocalBlockAIPayload): string {
  return Object.keys(data)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          data[key] as keyof LocalBlockAIPayload
        )}`
    )
    .join("&");
}

function getLocalChartBlockTextAxios(
  data: LocalBlockAIPayload,
  cb?: (e: ProgressEvent) => void
): Promise<AxiosResponse> {
  const serialized = serialize(data);
  return get("/ai/v1/local-chart-block?" + serialized, {
    ...(cb !== undefined && {
      onDownloadProgress: cb,
    }),
  });
}

export function getAiTweak(
  tweak: string,
  text: string,
  cb: (e: ProgressEvent) => void
): Promise<AxiosResponse> {
  return get(`/ai/v1/refine/${tweak}/?original=${encodeURIComponent(text)}`, {
    onDownloadProgress: cb,
  });
}

async function getBynderImages(
  tags?: string,
  page?: number,
  limit = 10
): Promise<any> {
  const endpoint = `${HOST}/bynder/v1/get-assets?collectionId=8D222F70-CDEE-4C2D-A88500F87C38C9CB&${tags}&page=${page}&limit=${limit}`;

  try {
    const result = await axios.get(endpoint, HEADERS());

    if (result) {
      return result;
    }
    return "Error getting images";
  } catch (error) {
    helpers.customSentryError(
      "Get Bynder Images Error",
      "Error getting images from services api.",
      {
        error: error,
      }
    );
    return error;
  }
}

// function put(resource, data) {
//     return axios.put(HOST + resource, data, HEADERS());
// }

// function deleteAxios(resource) {
//     return axios.delete(HOST + resource, HEADERS());
// }
