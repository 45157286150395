import TrackingService from "@/services/trackingservice/tracking.service";
import { KcmAutoposterJob, KcmAutoposterJobsResponse } from "@/types";
import { Commit } from "vuex";

interface AutoposterStore {
  autoposterJobs: KcmAutoposterJobsResponse | null;
}

export const state = {
  autoposterJobs: null,
};

export const getters = {
  autoposterJobs(state: AutoposterStore): KcmAutoposterJobsResponse | null {
    return state.autoposterJobs;
  },
};

export const actions = {
  setAutoposterJobs(
    { commit }: { commit: Commit },
    newValue: KcmAutoposterJobsResponse
  ): void {
    commit("AUTOPOSTER_JOBS", newValue);

    if (newValue?.data) {
      TrackingService.updateUserAttributes({
        automation_status: newValue.data.jobs?.some(
          (job: KcmAutoposterJob) => job.status
        ),
      });
    }
  },
};

export const mutations = {
  AUTOPOSTER_JOBS(
    state: AutoposterStore,
    newValue: KcmAutoposterJobsResponse | null
  ): void {
    state.autoposterJobs = newValue;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
